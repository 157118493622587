import React, { useEffect, useState } from 'react';

interface CountdownProps {
  endTime: string; // yyyy-mm-dd
}

const Countdown: React.FC<CountdownProps> = ({ endTime }) => {
  const [timer, setTimer] = useState({
    days: '00', hours: '00', minutes: '00', seconds: '00',
  });

  const format = (value: number): string => {
    if (value < 0) {
      return '00';
    }
    if (value < 10) {
      return `0${value}`;
    }
    return value.toString();
  };
  useEffect(() => {
    const countDownDate = new Date(endTime).getTime();

    const countdown = setInterval(() => {
      const now = new Date().getTime();

      const distance = countDownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimer({
        days: format(days),
        hours: format(hours),
        minutes: format(minutes),
        seconds: format(seconds),
      });
      if (distance < 0) {
        clearInterval(countdown);
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [endTime]);

  return (
    <div className="m-countdown">
      <div className="m-countdown-timeleft">
        <div className="m-countdown_wrapper">
          <div className="m-countdown-time">{timer.days}</div>
          <div className="m-countdown-unit">Ngày</div>
        </div>
      </div>
      <div className="m-countdown-timeleft">
        <div className="m-countdown_wrapper">
          <div className="m-countdown-time">{timer.hours}</div>
          <div className="m-countdown-unit">Giờ</div>
        </div>
      </div>
      <div className="m-countdown-timeleft">
        <div className="m-countdown_wrapper">
          <div className="m-countdown-time">{timer.minutes}</div>
          <div className="m-countdown-unit">Phút</div>
        </div>
      </div>
      <div className="m-countdown-timeleft">
        <div className="m-countdown_wrapper">
          <div className="m-countdown-time">{timer.seconds}</div>
          <div className="m-countdown-unit">Giây</div>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
