/* eslint-disable react/jsx-props-no-spreading */

import React, { useMemo, useState } from 'react';

import Banner from '../Banner';

import LayerLogin from './layout';

import abbottLogo from 'assets/images/abbott-logo.png';
import bannerDecorMb from 'assets/images/new/home/banner-decorator-mobile.png';
import bannerDecor from 'assets/images/new/home/banner-decorator.png';
import hospenLogo from 'assets/images/new/home/logo-vietspen.png';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import useWindowDimensions from 'hooks/useWindowDimension';

const initialize = {
  loginPopup: false,
  registerPopup: false,
  forgotPasswordPopup: false,
  successResetPasswordPopup: false,
};

const Login: React.FC = () => {
  const { width, height } = useWindowDimensions();
  const isHorizontal = useMemo(() => {
    if (width && height) {
      return width < 992 && width > height;
    }
    return false;
  }, [width, height]);
  const [openPopup, setOpenPopup] = useState(initialize);

  const handlePopup = (name?: string) => {
    setOpenPopup(name ? { ...initialize, [name]: true } : initialize);
  };

  return (
    <LayerLogin openPopup={openPopup} handlePopup={handlePopup}>
      <Banner customContent>
        <div className="t-bannerHome_content">
          <div className="t-bannerHome_logo">
            <div className="t-bannerHome_logo_item">
              <Image src={hospenLogo} ratio="342x144" />
            </div>
            <div className="t-bannerHome_logo_item">
              <Image src={abbottLogo} ratio="167x76" />
            </div>
          </div>
          <div className="t-bannerHome_title">
            <Text modifiers={['white', 'center', '26x39', 'brandon']}>
              HỘI THẢO KHOA HỌC
            </Text>
          </div>
          <div className="t-bannerHome_sologan">
            <Text modifiers={['linearGoldNew', 'center', '32x40', '700', 'brandonGrotesque', 'linearShadow']}>
              NÂNG CAO CHĂM SÓC DINH DƯỠNG
            </Text>
          </div>
          <div className="t-bannerHome_sologan">
            <Text modifiers={['linearGoldNew', 'center', '32x40', '700', 'brandonGrotesque', 'linearShadow']}>
              CHO BỆNH NHÂN NGOẠI KHOA
            </Text>
          </div>
          <div className="t-bannerHome_sologan section">
            <Text modifiers={['linearGoldNew', 'center', '32x40', '700', 'brandonGrotesque', 'linearShadow']}>
              NHU CẦU THIẾT THỰC GIÚP HỒI PHỤC SỚM
            </Text>
          </div>
          <div className="t-bannerHome_sologan">
            <Text modifiers={['linearGoldNew', 'center', '32x40', '700', 'brandonGrotesque', 'linearShadow']}>
              SAU PHẪU THUẬT VÀ SAU XUẤT VIỆN
            </Text>
          </div>
          <div className="t-bannerHome_image">
            <Image src={bannerDecor} srcTablet={isHorizontal ? bannerDecor : bannerDecorMb} srcMobile={bannerDecorMb} ratio={isHorizontal ? '1068x1144' : '800x870'} />
          </div>
          <div className="t-login_context">
            <div className="t-login_context_button">
              <Button
                size="h56"
                onClick={() => handlePopup('loginPopup')}
              >
                ĐĂNG NHẬP
              </Button>
            </div>
            <div className="t-login_context_register">
              <Text modifiers={['white', '24x36', '500', 'center']}>
                Chưa có tài khoản?
                <div
                  onClick={() => handlePopup('registerPopup')}
                >
                  <Text
                    modifiers={['bronze2']}
                    type="span"
                  >
                    Đăng ký ngay
                  </Text>
                </div>
              </Text>
            </div>
          </div>
        </div>
      </Banner>
    </LayerLogin>
  );
};

export default Login;
