import React from 'react';

import mapModifiers from 'utils/functions';

export const iconList = {
  soundBlue: 'soundBlue',
  muteBlue: 'muteBlue',
  mute: 'mute',
  sound: 'sound',
  minimize: 'minimize',
  fullscreen: 'fullscreen',
  redo: 'redo',
  eyeBlue: 'eyeBlue',
  play: 'play',
  supporter: 'supporter',
};

export type IconSize = '20x20' | '24x24' | '32x32' | '60x60' | 'big' | '16' | '34x40' | '48x48';
export type IconName = keyof typeof iconList;

interface IconProps {
  iconName: IconName;
  iconSize?: IconSize;
}

const Icon: React.FC<IconProps> = ({ iconName, iconSize }) => (
  <i className={mapModifiers('a-icon', iconName, iconSize)} />
);

Icon.defaultProps = {
  iconSize: undefined,
};

export default Icon;
