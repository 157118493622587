import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

import { GA_ID } from 'utils/constant';

const AppInitializer: React.FC = () => {
  const location = useLocation();
  const [initializedGA, setInitializedGA] = useState(false);

  //* GTM-GA
  useEffect(() => {
    ReactGA.initialize(GA_ID);
    setInitializedGA(true);
  }, []);

  useEffect(() => {
    if (initializedGA && location) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initializedGA, location]);
  //* End GTM-GA
  return null;
};

export default AppInitializer;
