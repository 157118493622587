import React, { memo, useRef, useLayoutEffect } from 'react';

import usePrevious from './hooks';

import Input, { InputProps } from 'components/atoms/Input';

export interface SingleOTPInputProps extends InputProps {
  focus?: boolean;
}

const SingleOTPInputComponent: React.FC<SingleOTPInputProps> = ({ focus, autoFocus, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const prevFocus = usePrevious(!!focus);

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return <Input ref={inputRef} modifiers="otp" {...rest} />;
};

export default memo(SingleOTPInputComponent);
