import React from 'react';

import loadingIcon from 'assets/images/loading.gif';
import mapModifiers from 'utils/functions';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  modifiers?: 'primary' | 'secondary' | 'resize';
  isSubmit?: boolean;
  loading?: boolean;
  size?: 'h44' | 'h46' | 'h58' | 'h56';
  hasBorder?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  modifiers,
  isSubmit,
  loading,
  disabled,
  onClick,
  children,
  size,
  hasBorder,
}) => (
  <button
    onClick={onClick}
    className={mapModifiers(
      'a-button',
      modifiers,
      size,
      loading && 'loading',
      hasBorder && 'border',
      disabled && 'disabled'
    )}
    type={isSubmit ? 'submit' : 'button'}
  >
    <div className="a-button_wrapper">
      {
        loading ? (
          <img
            className="a-button_loadingIcon"
            src={loadingIcon}
            alt="loading"
          />
        ) : <span>{children}</span>
      }
    </div>
  </button>
);

Button.defaultProps = {
  modifiers: 'primary',
  isSubmit: undefined,
  loading: undefined,
  size: undefined,
  hasBorder: undefined,
};

export default Button;
