/* eslint-disable react/jsx-no-useless-fragment */
import DOMPurify from 'dompurify';
import React from 'react';

import mapModifiers from 'utils/functions';

type Modifiers = GeneralTextStyle;

interface HeadingProps {
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  modifiers?: Modifiers[];
  content?: string;
  children?: React.ReactNode;
}

const Heading: React.FC<HeadingProps> = ({
  type,
  modifiers,
  children,
  content,
}) => {
  const Element = type;

  return (
    <>
      {content ? (
        <Element
          className={mapModifiers('a-heading', type, modifiers)}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
        />
      ) : (
        <Element className={mapModifiers('a-heading', type, modifiers)}>
          {children}
        </Element>
      )}
    </>
  );
};

Heading.defaultProps = {
  modifiers: ['400'],
  content: undefined,
};

export default Heading;
