/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { debounce } from 'lodash-es';
import { useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import eventConfigs from '../configs';

import useResumeFg from 'hooks/useResumeFg';
import getTokenWebSocketService from 'services/websocket';
import socket from 'services/websocket/socket';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setInteractives, setQuestions } from 'store/questions';
import { getSystemAction, setEndedLivestream, setSocketStatus } from 'store/system';

const SocketPush: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.profile.data);
  const throttledFetchSystem = useMemo(
    () => debounce(
      () => {
        if (location.pathname !== '/livestream') {
          console.log('Get System when resume from background');
          dispatch(getSystemAction());
        }
      },
      3000,
    ),
    [dispatch, location.pathname],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getSystemAction()).unwrap().then((res) => {
        if (res.redirectToPage === 'LIVESTREAM') {
          clearInterval(interval);
          if (location.pathname === '/foyer') {
            navigate(eventConfigs.SLUG_PARAMS.LIVESTREAM);
          }
        }
      });
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useResumeFg(throttledFetchSystem);

  useEffect(() => {
    if (user) {
      const { host, protocol } = window.location;
      const wsHost = `${protocol === 'https:' ? 'wss' : 'ws'}://${host}/ws`;
      // dev
      // const wsHost = 'wss://staging.hoithaoensure.com/ws';
      socket(
        wsHost,
        getTokenWebSocketService,
        {
          onMessage: (data: string) => {
            const parsedData = JSON.parse(data);
            switch (parsedData.event) {
              case 'openWaiting1':
              case 'openFoyer':
              case 'openThankYou':
                dispatch(getSystemAction(parsedData.event));
                break;
              case 'openLivestream':
                setTimeout(() => {
                  dispatch(getSystemAction(parsedData.event));
                }, 5000 + 3000 * Math.random());
                break;
              case 'notifyInteractiveExamAllUser':
                dispatch(setInteractives({
                  timeBroadcasted: parsedData.data.timeBroadcasted,
                  data: parsedData.data.interactiveActivityQuestion
                }));
                break;
              case 'Approved':
                dispatch(setQuestions({
                  ...parsedData.data,
                  approvedAt: parsedData.data.approved_at
                }));
                break;
              case 'EndedLivestream':
                dispatch(setEndedLivestream(true));
                break;
              default:
                break;
            }
          },
          onStateChange: (status) => {
            dispatch(setSocketStatus(status));
          }
        }
      );
    }
  }, [dispatch, user]);

  return null;
};

export default SocketPush;
