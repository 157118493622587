import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import getSystemService from 'services/system';
import { GeneralDataSystem } from 'services/system/types';

interface GeneralState {
  data?: GeneralDataSystem;
  loading?: boolean;
  onLive?: boolean;
  goLiveByLogin: boolean;
  socketStatus?: number;
  endedLivestream?: boolean;
}

const initialState: GeneralState = {
  data: undefined,
  loading: false,
  onLive: false,
  goLiveByLogin: false,
  endedLivestream: false,
};

export const getSystemAction = createAsyncThunk<
  GeneralDataSystem,
  string | undefined,
  { rejectValue: ErrorResponse }
>('general/getSystemAction', async (params, { rejectWithValue }) => {
  try {
    const res = await getSystemService(params);
    return res;
  } catch (error) {
    return rejectWithValue(error as ErrorResponse);
  }
});

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    pushToOnLive: ($state, action: PayloadAction<boolean>) => {
      $state.onLive = action.payload;
    },
    goLiveByLogin: ($state, action: PayloadAction<boolean>) => {
      $state.goLiveByLogin = action.payload;
    },
    setSocketStatus: ($state, action: PayloadAction<number>) => {
      $state.socketStatus = action.payload;
    },
    setEndedLivestream: ($state, action: PayloadAction<boolean>) => {
      $state.endedLivestream = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(getSystemAction.pending, ($state) => {
      $state.loading = true;
    });
    builder.addCase(getSystemAction.fulfilled, ($state, action) => {
      if ($state.data?.redirectToPage !== action.payload.redirectToPage) {
        $state.data = action.payload;
      }
      $state.loading = false;
    });
    builder.addCase(getSystemAction.rejected, ($state) => {
      $state.loading = false;
    });
  },
});

export const {
  pushToOnLive, goLiveByLogin,
  setSocketStatus, setEndedLivestream
} = generalSlice.actions;

export default generalSlice.reducer;
