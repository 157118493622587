import bsTam2 from 'assets/images/doctors/bs-tam2.png';
import bsThinh from 'assets/images/doctors/bs-thinh.png';
import bsTrung from 'assets/images/doctors/bs-trung.png';
import bsTuan from 'assets/images/doctors/bs-tuan.png';
import { OptionType } from 'components/molecules/Pulldown';
import { AgendaTypes } from 'components/templates/Agenda';
import { LivestreamTypes } from 'components/templates/Questions';
import { TestimonialsDataType } from 'components/templates/Testimonials';

export const doctorOptions: OptionType[] = [
  {
    value: '1',
    label: 'TS.BS. LƯU NGÂN TÂM',
  },
  {
    value: '2',
    label: 'PGS.TS.BS. LÊ THỊ TUYẾT LAN',
  },
  {
    value: '3',
    label: 'TS.BS. NGUYỄN HỮU QUÂN',
  },
];

const doctorData: TestimonialsDataType[] = [
  {
    id: 1,
    name: 'PGS. TS. BS. LÂM VIỆT TRUNG',
    role: '',
    img: bsTrung,
    position: ['Phó giám đốc Bệnh viện Chợ Rẫy'],
  },
  {
    id: 2,
    name: 'TS. BS. LƯU NGÂN TÂM',
    role: '',
    img: bsTam2,
    position: ['Trưởng khoa Dinh dưỡng Bệnh viện Chợ Rẫy', 'Chủ tịch Hội Nuôi dưỡng đường tĩnh mạch và', 'đường tiêu hóa Việt Nam (VietSPEN)'],
  },
];

export const doctorData2: TestimonialsDataType[] = [
  {
    id: 1,
    name: 'TS. BS. LÊ QUAN ANH TUẤN',
    role: '',
    img: bsTuan,
    position: ['Phó chủ tịch Chi hội Tăng cường phục hồi', 'sau mổ Việt Nam (ERAS)', 'Trưởng khoa Ngoại Gan Mật Tụy', 'Bệnh viện Đại học Y Dược TP.HCM'],
  },
  {
    id: 2,
    name: 'PGS. TS. BS. NGUYỄN HỮU THỊNH',
    role: '',
    img: bsThinh,
    position: ['Trưởng khoa Khám sức khoẻ theo yêu cầu', 'Bệnh viện Đại học Y Dược TP. HCM'],
  },
];

export const agendaData: AgendaTypes[] = [
  {
    time: '08:00 - 09:00',
    title: 'ĐÓN TIẾP ĐẠI BIỂU',
    speaker: ['Tất cả chuyên viên y tế'],
  },
  {
    time: '09:00 - 09:10',
    title: 'PHÁT BIỂU KHAI MẠC',
    speaker: ['PGS. TS. BS. Lâm Việt Trung', 'TS. BS. Lưu Ngân Tâm'],
  },
  {
    time: '09:10 - 09:20',
    title: 'PHÁT BIỂU CHÀO MỪNG TỪ ABBOTT',
    speaker: ['Đại diện Abbott Việt Nam'],
  },
  {
    time: '09:20- 09:50',
    title: 'Báo cáo khoa học 1',
    subTitle: 'DINH DƯỠNG TRONG TĂNG CƯỜNG HỒI PHỤC SỚM SAU PHẪU THUẬT',
    speaker: ['TS. BS. Lê Quan Anh Tuấn'],
    classSubTitle: 't-agenda_item_subTitle-bckh1'
  },
];

export const agendaData2: AgendaTypes[] = [
  {
    time: '09:50 - 10:20',
    title: 'Báo cáo khoa học 2',
    subTitle: 'CÁC VẤN ĐỀ TRONG CAN THIỆP DINH DƯỠNG LÂM SÀNG Ở BỆNH NHÂN NGOẠI KHOA',
    speaker: ['PGS. TS. BS. Nguyễn Hữu Thịnh'],
    classSubTitle: 't-agenda_item_subTitle-bckh2'
  },
  {
    time: '10:20 - 11:00',
    title: 'Báo cáo khoa học 3',
    subTitle: 'DINH DƯỠNG TRONG TĂNG CƯỜNG TỔNG HỢP PROTEIN VÀ KHỐI CƠ GIÚP TĂNG HIỆU QUẢ ĐIỀU TRỊ TRONG NGOẠI KHOA',
    speaker: ['TS. BS. Lưu Ngân Tâm'],
    classSubTitle: 't-agenda_item_subTitle-bckh3'
  },
  {
    time: '11:00 - 11:45',
    title: 'THẢO LUẬN',
    speaker: ['Tất cả chuyên viên y tế'],
  },
  {
    time: '11:45 - 12:00',
    title: 'TỔNG KẾT VÀ BẾ MẠC',
    speaker: ['PGS. TS. BS. Lâm Việt Trung', 'TS. BS. Lưu Ngân Tâm'],
  },
];

export const livestreamData: LivestreamTypes[] = [
  {
    numberQuestion: '1',
    title: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
    numberAreed: ''
  },
  {
    numberQuestion: '1',
    title: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
    numberAreed: '2034'
  },
  {
    numberQuestion: '2',
    title: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
    numberAreed: ''
  },
  {
    numberQuestion: '2',
    title: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
    numberAreed: '2034'
  },
  {
    numberQuestion: '3',
    title: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
    numberAreed: ''
  },
  {
    numberQuestion: '3',
    title: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
    numberAreed: '2034'
  },
];

export default doctorData;
