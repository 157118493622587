/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Certificate, { UserInfoCertification } from 'components/templates/Certificate';
import CmeSurveyTemplate from 'components/templates/CmeSurvey';
import DeviceWrapped from 'components/templates/DeviceOriented';
import FinishLivePopup from 'components/templates/FinishLivePopup';
import eventConfig from 'configs';
import getCmeQuestionService from 'services/cme';
import { CmeQuestionDataTypes } from 'services/cme/type';
import { getLocalStorage, setLocalStorage } from 'services/common/storage';
import { useAppSelector } from 'store/hooks';

const CmeSurvey: React.FC = () => {
  /**
   * 0: Certificate
   * 1: Test ready
   */
  const [step, setStep] = useState(0);
  const [listQuestion, setListQuestion] = useState<CmeQuestionDataTypes[]>([]);
  const { data: memberInfo } = useAppSelector((state) => state.profile);
  const [openPopup, setOpenPopup] = useState(true);
  // const [userInfo, setUserInfo] = useState<ProfileResponseType>();
  const checkPopup = getLocalStorage('closeEndLive');
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
        const res = await getCmeQuestionService();
        setListQuestion(res);
      } catch {
        // empty
      }
    })();
  }, []);

  const userInfo: UserInfoCertification = useMemo(() => ({
    fullname: memberInfo?.fullName || '',
    companyAddress: memberInfo?.companyAddress || '',
    specialist: memberInfo?.specialist || '',
  }), [memberInfo]);

  useEffect(() => {
    if (memberInfo?.allowCme === false) {
      setStep(1);
    }
    if (memberInfo?.allowSurvey === true) {
      navigate(eventConfig.SLUG_PARAMS.SURVEY);
    }
  }, [memberInfo, navigate]);

  if (!memberInfo) {
    return <>Loading...</>;
  }

  return (
    <DeviceWrapped>
      {/* <FinishLivePopup
        openPopup={checkPopup !== 'done'}
        handleCme={() => {
          setOpenPopup(false);
          setLocalStorage('closeEndLive', 'done');
        }}
      /> */}
      <main className="wrapper p-cmesurvey">
        {(() => {
          switch (step) {
            case 1:
              return (
                <CmeSurveyTemplate listQuestion={listQuestion} />
              );

            default:
              return (
                <Certificate
                  userInfo={userInfo}
                  step={step}
                  handleSubmit={() => setStep(1)}
                />
              );
          }
        })()}
      </main>

    </DeviceWrapped>
  );
};

export default CmeSurvey;
