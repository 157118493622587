function mapModifiers(
  baseClassName: string,
  ...modifiers: (string | string[] | false | undefined)[]
): string {
  return modifiers
    .reduce<string[]>(
      (acc, m) => (!m ? acc : [...acc, ...(typeof m === 'string' ? [m] : m)]),
      [],
    )
    .map((m) => `-${m}`)
    .reduce<string>(
      (classNames, suffix) => `${classNames} ${baseClassName}${suffix}`,
      baseClassName,
    );
}

export default mapModifiers;

/*!
 * Scroll down to next block element
 */
export function scrollDownNextSection(ref: React.RefObject<HTMLDivElement>) {
  if (ref && ref.current) {
    window.scrollTo(
      { behavior: 'smooth', top: ref.current.offsetTop },
    ); // Minus header height
  }
}

/*!
 * getMousePosition(event) - cross browser normalizing of:
 * clientX, clientY, screenX, screenY, offsetX, offsetY, pageX, pageY
 * HTMLElement
 */
export function getMousePosition(
  evt:
    | React.MouseEvent<SVGPathElement, MouseEvent>
    | React.MouseEvent<SVGRectElement, MouseEvent>,
  item: HTMLDivElement,
) {
  let { pageX } = evt;
  let { pageY } = evt;
  if (pageX === undefined) {
    pageX = evt.clientX
      + document.body.scrollLeft
      + document.documentElement.scrollLeft;
    pageY = evt.clientY
      + document.body.scrollTop
      + document.documentElement.scrollTop;
  }

  const rect = item.getBoundingClientRect();
  const offsetX = evt.clientX - rect.left;
  const offsetY = evt.clientY - rect.top;

  return {
    client: { x: evt.clientX, y: evt.clientY }, // relative to the viewport
    screen: { x: evt.screenX, y: evt.screenY }, // relative to the physical screen
    offset: { x: offsetX, y: offsetY }, // relative to the event target
    page: { x: pageX, y: pageY }, // relative to the html document
  };
}

export function getDimensions(ele: HTMLDivElement) {
  const { height } = ele.getBoundingClientRect();
  const { offsetTop } = ele;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
}

export function scrollStop(callback: (value: any) => void, time = 2000) {
  // Make sure a valid callback was provided
  if (!callback || typeof callback !== 'function') return;

  // Setup scrolling variable
  let isScrolling: any;

  // Listen for scroll events
  window.addEventListener(
    'scroll',
    () => {
      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(callback, time);
    },
    false,
  );
}

export function getMonth(date: Date) {
  return new Date(date).getMonth();
}

export function range(start: number, end: number) {
  return new Array(end - start + 1).fill(undefined).map((_, i) => ({
    label: String(end - i),
    value: String(end - i),
  }));
}

export const years = range(1920, new Date().getFullYear());

export const months = [
  { label: 'January', value: 'January' },
  { label: 'February', value: 'February' },
  { label: 'March', value: 'March' },
  { label: 'April', value: 'April' },
  { label: 'May', value: 'May' },
  { label: 'June', value: 'June' },
  { label: 'July', value: 'July' },
  { label: 'August', value: 'August' },
  { label: 'September', value: 'September' },
  { label: 'October', value: 'October' },
  { label: 'November', value: 'November' },
  { label: 'December', value: 'December' },
];

export function convertLocalToUTCDate(date: Date) {
  if (!date) {
    return date;
  }
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
  );
}

const formatKey = (key: string) => key.replace(/[A-Z]/g, '_$&').toLowerCase();

export function formatParams<T>(params?: T) {
  if (!params) return undefined;
  return Object.keys(params).reduce(
    (prev, cur) => {
      const obj: any = {};
      const key = formatKey(cur);
      obj[key] = params[cur as keyof T];
      return ({ ...prev, ...obj });
    },
    {}
  );
}

export const getDateTimeTracking = (): string => {
  const today = new Date();
  return `${today.getFullYear()}-${today.getMonth() + 1 < 10
    ? `0${today.getMonth() + 1}`
    : today.getMonth() + 1
    }-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()} ${today.getHours() < 10 ? `0${today.getHours()}` : today.getHours()
    }:${today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()
    }:${today.getSeconds() < 10 ? `0${today.getSeconds()}` : today.getSeconds()}`;
};

export const getTimeLocate = (date: Date) => {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  const strTime = `${hours}:${minutes < 10 ? `0${minutes}` : minutes} ${ampm}`;
  return strTime;
};

export const formatDateDDMMYYYY = (date?: string, prefix?: string) => {
  if (!date) return '';
  const pref = prefix || '.';
  const dateFormat = new Date(date);
  let day: string | number = dateFormat.getDate();
  let month: string | number = dateFormat.getMonth() + 1;
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return `${day}${pref}${month}${pref}${dateFormat.getFullYear()}`;
};

export const imageLoader = (url: string) => new Promise<HTMLImageElement>((resolve, reject) => {
  const img = new Image();

  img.crossOrigin = 'anonymous';

  img.onload = () => {
    resolve(img);
  };
  img.onerror = (err) => {
    reject(err);
  };
  img.src = url;
});

export const cleanRegionPhoneNumber = (phone: string) => {
  const ass = phone.split('');
  if (ass[0] === '0') {
    ass.shift();
    return ass.join('');
  }
  return phone;
};

export function reverseArr(input: Array<any>) {
  const ret: any[] = [];
  for (let i = input.length - 1; i >= 0; i -= 1) {
    ret.push(input[i]);
  }
  return ret;
}
