import { ConditionalListTypes, ForgotPasswordParamsTypes, LoginParamsTypes } from './type';

import axiosInstance from 'services/common/instance';

export const forgotPasswordService = async (params: ForgotPasswordParamsTypes):Promise<any> => {
  const res = await axiosInstance.post('/members/forgot-password', params);
  return res.data;
};

const loginEventService = async (params: LoginParamsTypes):Promise<any> => {
  const res = await axiosInstance.post('/members/login', params);
  return res.data;
};

export const getConditionalListService = async (): Promise<ConditionalListTypes> => {
  const res = await axiosInstance.post('members/conditions-list');
  return res.data.data;
};

export default loginEventService;
