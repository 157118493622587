/* eslint-disable camelcase */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import OtpPopup from '../OtpPopup';
import PolicyPopup from '../PolicyPopup';

import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Input from 'components/atoms/Input';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import Container from 'components/organisms/Container';
import Form from 'components/organisms/Form';
import Popup from 'components/organisms/Popup';
import useCountOpenOnceTime from 'hooks/useCountOpen';
import useDebounce from 'hooks/useDebounce';
import { checkInvitationCodeService, checkRegisterService, registerMemberService } from 'services/register';
import mapModifiers from 'utils/functions';
import registerSchema from 'utils/schemas';

interface RegisterFormDataType {
  invitation_code: string;
  full_name: string;
  email: string;
  phone: string;
  company_address?: string;
  specialist?: string;
}
interface RegisterProps {
  modifier?: 'popup';
  disabledLogin?: boolean;
  handleLogin?: () => void;
}

const Register: React.FC<RegisterProps> = ({
  modifier, disabledLogin, handleLogin,
}) => {
  const [openPopup, setOpenPopup] = useState({
    policy: false,
    otp: false,
    success: false,
  });
  const method = useForm<RegisterFormDataType>({
    resolver: yupResolver(registerSchema),
  });
  const { countPolicyOpen, handleListenerOpen } = useCountOpenOnceTime();
  const [isLoadingSubmit, setLoadingSubmit] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [errorOtp, setErrorOtp] = useState<string>('');
  const [invitationCode, setInvitationCode] = useState('');

  const [registerData, setRegisterData] = useState<RegisterFormDataType>({
    invitation_code: '',
    phone: '',
    email: '',
    full_name: '',
    specialist: '',
    company_address: '',
  });

  /**
   * OTP VALID
   */
  const handleSubmitOtp = async (val: string) => {
    setOtpLoading(true);
    try {
      await registerMemberService({
        invitation_code: registerData.invitation_code,
        phone: registerData.phone,
        otp: val,
        email: registerData.email,
        full_name: registerData.full_name,
        specialist: registerData.specialist,
        company_address: registerData.company_address,
      });
      // close OTP input popup
      setOpenPopup((prevState) => ({
        ...prevState,
        otp: false,
        success: true,
      }));
      method.reset();
      setErrorOtp('');
    } catch (error) {
      setErrorOtp('Mã OTP không hợp lệ. Xin vui lòng nhập lại');
    } finally {
      setOtpLoading(false);
    }
  };

  /**
   * CHECK REGISTER VALID
   */
  async function checkRegister(data: RegisterFormDataType) {
    setLoadingSubmit(true);
    try {
      await checkRegisterService({
        invitation_code: data.invitation_code,
        phone: data.phone,
        email: data.email,
      });
      setOpenPopup((prevState) => ({
        ...prevState,
        otp: true,
      }));
    } catch (error) {
      if (Array.isArray(error)) {
        error.forEach((err) => {
          method.setError(err.field, { message: err.message });
        });
      }
    } finally {
      setLoadingSubmit(false);
    }
  }

  /**
   * REGISTER FORM SUBMIT
   */
  const handleSubmit = (data: RegisterFormDataType) => {
    setRegisterData(data);
    if (countPolicyOpen > 0) {
      checkRegister(data);
    } else {
      setOpenPopup((prevState) => ({
        ...prevState,
        policy: true,
      }));
    }
  };

  // HANDLE ACCEPT POLICY
  const handleAcceptPolicy = () => {
    if (Object.keys(method.formState.errors).map((key) => key).length === 0) {
      checkRegister(method.getValues());
      if (modifier === 'popup') setRegisterData(method.getValues());
    }
  };

  // AUTOFILL BY INVITATION CODE

  const validateField = useCallback(
    async (value: string) => {
      if (value) {
        method.clearErrors();
        try {
          const res = await checkInvitationCodeService({
            invitation_code: value,
          });
          method.setValue('email', res.data.hcpEmail || '');
          method.setValue('full_name', res.data.hcpEnglishName || '');
          method.setValue('phone', res.data.hcpPhone || '');
          method.setValue('company_address', res.data.hcoName || '');
          method.setValue('specialist', res.data.departmentInVietnamese || '');
          method.setError('invitation_code', { message: '' });
        } catch (error) {
          if (error) {
            const errors = [...(error as ErrorResponse[])];
            if (errors.length) {
              method.setError('invitation_code', {
                message: errors[0].message,
              });
            }
          }
        }
      } else {
        method.setError('invitation_code', { message: '' });
      }
    },
    [method],
  );
  const invitationCodeDB = useDebounce(invitationCode, 500);

  useEffect(() => {
    validateField(invitationCodeDB);
  }, [invitationCodeDB, validateField]);

  return (
    <>
      <div className={mapModifiers('t-register', modifier)}>
        <Container>
          {modifier !== 'popup' && (
            <div className="t-register_heading">
              <Heading
                type="h2"
                modifiers={['40x56', 'uppercase', '700', 'center']}
              >
                Đăng Ký tham gia
              </Heading>
            </div>
          )}
          <Form method={method} submitForm={handleSubmit}>
            <div className="t-register_wrapForm">
              <div className="t-register_form">
                <div className="t-register_input">
                  <Controller
                    control={method.control}
                    name="invitation_code"
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        isRequired
                        label="Mã số thư mời"
                        id="invitation_code"
                        value={value}
                        placeholder="Mã số thư mời"
                        type="text"
                        modifiers={modifier?.includes('popup') ? ['border', 'h48'] : 'default'}
                        error={error?.message}
                        handleChange={(e) => {
                          onChange(e);
                          setInvitationCode(e.currentTarget.value);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="t-register_input">
                  <Controller
                    control={method.control}
                    name="full_name"
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        isRequired
                        label="Họ và tên"
                        id="full_name"
                        modifiers={modifier?.includes('popup') ? ['border', 'h48'] : 'default'}
                        value={value}
                        placeholder="Họ và tên"
                        type="text"
                        error={error?.message}
                        handleChange={onChange}
                      />
                    )}
                  />
                </div>
                <div className="t-register_input">
                  <Controller
                    control={method.control}
                    name="email"
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        isRequired
                        label="Email"
                        id="email"
                        modifiers={modifier?.includes('popup') ? ['border', 'h48'] : 'default'}
                        value={value}
                        placeholder="Email của bạn"
                        type="text"
                        error={error?.message}
                        handleChange={onChange}
                      />
                    )}
                  />
                </div>
                <div className="t-register_input">
                  <Controller
                    control={method.control}
                    name="phone"
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        isRequired
                        label="Số điện thoại"
                        id="phone"
                        value={value}
                        placeholder="Số điện thoại cá nhân"
                        type="text"
                        modifiers={modifier?.includes('popup') ? ['border', 'h48'] : 'default'}
                        error={error?.message}
                        handleChange={onChange}
                      />
                    )}
                  />
                </div>
                <div className="t-register_input">
                  <Controller
                    control={method.control}
                    name="company_address"
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        isRequired
                        label="Nơi công tác"
                        id="company_address"
                        value={value}
                        placeholder="Địa chỉ nơi công tác"
                        type="text"
                        modifiers={modifier?.includes('popup') ? ['border', 'h48'] : 'default'}
                        error={error?.message}
                        handleChange={onChange}
                      />
                    )}
                  />
                </div>
                <div className="t-register_input">
                  <Controller
                    control={method.control}
                    name="specialist"
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        isRequired
                        label="Chuyên khoa"
                        id="specialist"
                        value={value}
                        placeholder="Nhập chuyên khoa"
                        type="text"
                        modifiers={modifier?.includes('popup') ? ['border', 'h48'] : 'default'}
                        error={error?.message}
                        handleChange={onChange}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="t-register_button">
              {modifier === 'popup' ? (
                <Button
                  size="h44"
                  loading={isLoadingSubmit}
                  isSubmit
                >
                  Gửi thông tin
                </Button>
              ) : (
                <Button
                  size="h56"
                  loading={isLoadingSubmit}
                  isSubmit
                >
                  GỬI THÔNG TIN
                </Button>
              )}
            </div>
            {modifier === 'popup' && !disabledLogin && (
              <div className="t-register_wrapRegister">
                <Text type="span" modifiers={['24x32', 'pattrickBlue']}>
                  Bạn đã có tài khoản?
                </Text>
                <div
                  className="t-register_register"
                  onClick={() => handleLogin && handleLogin()}
                >
                  <Text type="span" modifiers={['700', '24x32']}>
                    Đăng nhập
                  </Text>
                </div>
              </div>
            )}
            <div className="mt-3">
              <Text type="div" modifiers={['20x28', 'pattrickBlue', 'center', '400']}>
                {
                  modifier !== 'popup' && (
                    <span>
                      Việc đăng ký đồng nghĩa với việc Quý vị cho
                      phép Abbott Laboratories GmbH lưu trữ các thông tin Quý vị cung cấp.
                      <br />
                      Quý vị cũng có thể hủy tham gia chương trình nếu muốn
                    </span>
                  )
                }
                <div
                  className="mt-1"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setOpenPopup((prev) => ({
                    ...prev,
                    policy: true,
                  }))}
                >
                  <Text
                    modifiers={['underline']}
                  >
                    Xem điều khoản lưu trữ thông tin
                  </Text>
                </div>
              </Text>
            </div>

          </Form>
        </Container>
      </div>

      {/* POPUP  */}
      {/* POLICY  */}
      <PolicyPopup
        isOpen={openPopup.policy}
        handleClose={() => setOpenPopup((prev) => ({
          ...prev, policy: false,
        }))}
        handleOK={() => {
          handleListenerOpen();
          handleAcceptPolicy();
          setOpenPopup((prevState) => ({
            ...prevState,
            policy: false,
          }));
        }}
      />

      {/* OTP POPUP  */}
      <OtpPopup
        error={errorOtp}
        phoneValue={method.getValues().phone}
        handleClose={() => {
          setOpenPopup((prev) => ({
            ...prev, otp: false,
          }));
          setErrorOtp('');
        }}
        openPopup={openPopup.otp}
        handleSubmit={(val) => handleSubmitOtp(val)}
        handleResend={() => checkRegister(method.getValues())}
        loading={otpLoading}
      />
      {/* VERIFIED POPUP SUCCESSFULLY POPUP */}
      <Popup
        title="ĐĂNG KÝ THÀNH CÔNG "
        isOpenModal={openPopup.success}
        handleClose={() => setOpenPopup((prevState) => ({
          ...prevState,
          success: false,
        }))}
        modifiers="success"
      >
        <div className="o-popup_notice">
          <Text modifiers={['pattrickBlue', '18x28', '500', 'center']}>
            <Text>
              Chúc mừng Quý Chuyên viên Y tế đã đăng ký tham dự thành công.
            </Text>
            Chương trình hội thảo khoa học sẽ diễn ra vào lúc

            <Text modifiers={['pattrickBlue', 'center', '700']}>
              8 giờ 00 phút ngày 18/08/2024
            </Text>
            Quý vị vui lòng truy cập trang web
            {' '}
            <Text>
              <Link href="http://www.hoithaoensure.com/" target="_blank" useExternal>
                <Text type="span" modifiers={['pattrickBlue', '700', 'underline']}>
                  www.hoithaoensure.com
                </Text>
              </Link>
              {' '}
              để tham dự chương trình.
            </Text>
          </Text>
          <br />
        </div>
        <div className="o-popup_button">
          <Button
            size="h44"
            onClick={() => {
              if (handleLogin && modifier?.includes('popup')) { handleLogin(); }
              setOpenPopup((prevState) => ({
                ...prevState,
                success: false,
              }));
            }}
          >
            HOÀN TẤT
          </Button>
        </div>
      </Popup>
    </>
  );
};

Register.defaultProps = {
  modifier: undefined,
  handleLogin: undefined,
};

export default Register;
