/* eslint-disable react/require-default-props */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useRef, useState } from 'react';

import mapModifiers from 'utils/functions';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  vertical?: boolean;
  isSquare?: boolean;
}
type ProviderValue<V> = {
  [group in keyof V]: V[group];
};
export interface RadioProviderHookProps<V = {}> {
  defaultValue?: ProviderValue<V>;
}

export const useRadioProvider = <V,>(props: RadioProviderHookProps<V> = {}) => {
  const radioValues = useRef<ProviderValue<V>>(
    props.defaultValue || ({} as ProviderValue<V>),
  );

  const [, setState] = useState<{ slot: number }>({
    slot: 0,
  });

  const setCheckedValue = <K extends keyof V>(
    group: K,
    checkedValue: V[K],
    silent?: boolean,
  ) => {
    radioValues.current = {
      ...radioValues.current,
      [group]: checkedValue,
    };

    if (!silent) {
      setState((prevState) => ({
        ...prevState,
        slot: prevState.slot + 1,
      }));
    }
  };

  const bind = useCallback(
    <K extends keyof V>(group: K) => (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      setCheckedValue(group, (event.target.value as unknown) as V[K]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const bindSilent = useCallback(
    <K extends keyof V>(group: K) => (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      setCheckedValue(group, (event.target.value as unknown) as V[K], true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const isChecked = useCallback(
    <K extends keyof V>(group: K, value: V[K]) => radioValues.current?.[group] === value,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getCheckedValueGroup = useCallback(
    <K extends keyof V>(group: K) => radioValues.current?.[group],
    [],
  );

  return {
    setCheckedValue,
    bind,
    bindSilent,
    isChecked,
    getCheckedValueGroup,
  };
};

export const Radio: React.FC<Props> = ({
  children,
  disabled,
  vertical,
  isSquare,
  id,
  ...innerProps
}) => (
  <div className={mapModifiers('a-radio', disabled && 'a-radio', vertical && 'vertical', isSquare && 'square')}>
    <label className="a-radio_label" htmlFor={id}>
      <input className="a-radio_input" {...innerProps} checked={innerProps.checked} id={id} type="radio" hidden />
      <div className="a-radio_body">
        <span className="a-radio_inner" />
      </div>
      {children && (
        <div
          className="a-radio_content"
        >
          <p dangerouslySetInnerHTML={{ __html: String(children) || '' }} />
        </div>
      )}
    </label>
  </div>
);
