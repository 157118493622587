import React from 'react';

import Icon from 'components/atoms/Icon';
import Text from 'components/atoms/Text';

export type LiveStreamQuestionType = {
  id: number;
  question: string;
  speaker: string;
  approvedAt: string;
};
type LiveCommentItemProps = {
  name: string;
  time: string;
  children?: React.ReactNode;
};

interface LiveCommentProps {
  commentList?: LiveStreamQuestionType[];
}

const LiveCommentItem: React.FC<LiveCommentItemProps> = ({
  name,
  time,
  children,
}) => (
  <div className="o-liveComment_comments_wrapper">
    <div className="o-liveComment_comments_head">
      <div className="name">
        <Icon iconName="redo" iconSize="24x24" />
        <Text modifiers={['700', 'uppercase', '18x28']}>
          {name}
        </Text>
      </div>
      <div className="time">
        <Text modifiers={['16x24']}>{time}</Text>
      </div>
    </div>
    <div className="o-liveComment_comments_content">
      <Text modifiers={['16x24']}>{children}</Text>
    </div>
  </div>
);

const LiveComment: React.FC<LiveCommentProps> = ({ commentList }) => (
  <div className="o-liveComment">
    {commentList
      && commentList.map((val, idx) => (
        <div className="o-liveComment_item" key={`item${idx.toString()}`}>
          <LiveCommentItem name={val.speaker} time={val.approvedAt}>
            {val.question}
          </LiveCommentItem>
        </div>
      ))}
  </div>
);

LiveComment.defaultProps = {
  commentList: undefined,
};

export default LiveComment;
