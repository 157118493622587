import React from 'react';

import Icon from 'components/atoms/Icon';

interface SoundControlProps {
  isMuted: boolean;
  handleSound?: () => void;
}

const SoundControl: React.FC<SoundControlProps> = (
  { isMuted, handleSound },
) => (
  <div
    className={`o-soundControl${isMuted ? ' muted' : ''}`}
    onClick={handleSound}
  >
    <Icon iconName={isMuted ? 'muteBlue' : 'soundBlue'} iconSize="48x48" />
  </div>
);

SoundControl.defaultProps = {
  handleSound: undefined,
};

export default SoundControl;
