/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';

import FoyerNoticePopup from '../FoyerNoticePopup';
import PlayerPopup from '../PlayerPopup';

import iconLoading from 'assets/images/loading.gif';
import Player from 'components/organisms/Player';
import SoundControl from 'components/organisms/SoundControl';
import WaitingLivestreamPopup from 'components/templates/WaitingLivestreamPopup';
import useMutedAutoplay from 'hooks/useMutedAutoplay';
import useResumeFg from 'hooks/useResumeFg';
import useWindowDimensions from 'hooks/useWindowDimension';
import { getLocalStorage, setLocalStorage } from 'services/common/storage';
import { FoyerConfigData, FoyerDiemChamType } from 'services/configs/type';
import { activityTrackingService } from 'services/tracking';
import { LOCALSTORAGE_FIELDNAME } from 'utils/constant';

interface FoyerProps {
  foyerData?: FoyerConfigData;
  active?: boolean;
}

const returnDescriptionFoyerStep = (step: number): string => {
  switch (step) {
    case 1:
      return 'vach1';
    case 2:
      return 'vach2';
    case 3:
      return 'vach3';
    case 4:
      return 'vach4';
    case 5:
      return 'vach5';
    case 6:
      return 'vach6';
    case 7:
      return 'cham13';
    case 8:
      return 'cham14';
    case 9:
      return 'cham15';
    case 10:
      return 'game';
    default:
      return '';
  }
};

const positionConfigs = [
  { left: 22.69, bottom: 8 },
  { left: 43.19, bottom: 8 },
  { left: 63.69, bottom: 8 },
];

const Foyer: React.FC<FoyerProps> = ({
  foyerData, active,
}) => {
  const { width, height } = useWindowDimensions();
  const [isMuted, setIsMuted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [boothIndex, setBoothIndex] = useState(-1);
  const [boothTracking, setBoothTracking] = useState(
    [...Array(foyerData?.diemcham.length)].fill(false),
  );
  const [openPopup, setOpenPopup] = useState({
    endFoyer: false,
    videoPopup: false,
    layerBooth: false,
    thanks: false,
    notice: false,
    gameIndex: -1
  });
  const backgroundVideoRef = useRef<HTMLVideoElement>(null);
  useMutedAutoplay(backgroundVideoRef, setIsMuted, active);
  const videoBackgroundTimeUpdate = (time: number) => {
    if (!foyerData) return;

    const video = backgroundVideoRef.current;
    if (video) {
      foyerData.diemcham.forEach((val, idx) => {
        if (time >= val.timecode && !boothTracking[idx]) {
          setLocalStorage(
            LOCALSTORAGE_FIELDNAME.CURRENT_TIME,
            idx.toString()
          );
          setBoothIndex(idx);
          setOpenPopup((prevState) => ({
            ...prevState,
            layerBooth: true,
          }));
          const clone = [...boothTracking];
          clone[idx] = true;
          setBoothTracking(clone);
          setTimeout(() => {
            video.pause();
          }, 10);
        }
      });
    }
  };

  const returnStyle = useCallback((idx: number): { left: string, bottom: string } => {
    if (!width || !height) {
      return { left: '0', bottom: '0' };
    }
    const isCorrect = width / height >= 16 / 9;
    if (isCorrect) {
      return {
        // calc((7.8% * calc(var(--screen-height) * 16 / 9 / var(--screen-width))) + ((var(--screen-width) - calc(var(--screen-height) * 16 / 9)) / var(--screen-width) * 50%))
        left: `${positionConfigs[idx].left * (height * 16 / 9 / width) + ((width - (height * 16 / 9)) / width * 50)}%`,
        bottom: `${positionConfigs[idx].bottom}%`,
      };
    }
    return {
      left: `${positionConfigs[idx].left}%`,
      bottom: `${positionConfigs[idx].bottom * (width * 9 / 16 / height) + ((height - (width * 9 / 16)) / height * 50)}%`,
    };
  }, [width, height]);

  const trackingUserOnlineFoyer = useCallback(async (step: number) => {
    try {
      await activityTrackingService({
        stepNumber: step,
        stepDescription: returnDescriptionFoyerStep(step)
      });
    } catch {
      // empty
    }
  }, []);

  useEffect(() => {
    if (boothIndex > -1) {
      trackingUserOnlineFoyer(boothIndex + 1);
    }
  }, [boothIndex, trackingUserOnlineFoyer]);

  const playBackgroundVideo = useCallback((currentTime?: number) => {
    const video = backgroundVideoRef.current;
    if (video) {
      if (currentTime) {
        backgroundVideoRef.current.currentTime = currentTime;
        video.play();
      } else {
        video.play();
      }
    }
  }, [backgroundVideoRef]);

  const handleContinueVideo = () => {
    setOpenPopup((prev) => ({
      ...prev,
      layerBooth: false,
      videoPopup: false,
    }));
    setTimeout(() => {
      playBackgroundVideo();
    }, 400);
  };

  /**
 * Save Current time in Foyer
 */

  const onLoadedVideo = useCallback(() => {
    const initial = getLocalStorage(LOCALSTORAGE_FIELDNAME.CURRENT_TIME);
    const isDone = getLocalStorage(LOCALSTORAGE_FIELDNAME.FOYER_DONE);
    if (isDone) {
      setOpenPopup((prev) => ({
        ...prev, endFoyer: true,
      }));
      backgroundVideoRef.current?.pause();
    } else if (initial) {
      const parseInitial = parseInt(initial, 10);
      if (foyerData && parseInitial < foyerData.diemcham.length) {
        if (backgroundVideoRef.current) {
          backgroundVideoRef.current.currentTime = foyerData.diemcham[parseInitial].timecode;
          if (parseInitial > 0) {
            setBoothTracking([...Array(parseInitial).fill(true), ...Array(foyerData.diemcham.length - parseInitial).fill(false)]);
          }
          // setTimeout(() => {
          //   backgroundVideoRef.current?.pause();
          // }, 10);
        }
      }
    }
  }, [foyerData]);

  useResumeFg(() => {
    if (backgroundVideoRef.current
      && backgroundVideoRef.current.paused
      && !openPopup.layerBooth && !openPopup.endFoyer && !openPopup.videoPopup) {
      if (!isMuted) {
        setIsMuted(true);
      }
      backgroundVideoRef.current.play();
    }
  });

  /**
   * EVENTS
   */
  const handleEnd = async () => {
    setLocalStorage(LOCALSTORAGE_FIELDNAME.FOYER_DONE, '1');
    setOpenPopup((prev) => ({
      ...prev, endFoyer: true,
    }));
    // await activityTrackingService({
    //   stepNumber: 2,
    //   stepDescription: 'endFoyer'
    // });
  };

  return (
    <>
      <div className="t-foyer">
        <div className="t-foyer_concept">
          <div className="t-foyer_concept_video">
            <Player
              isScreen
              autoplay
              onTimeUpdate={videoBackgroundTimeUpdate}
              isMuted={isMuted}
              src={foyerData?.video_hanhtrinh || ''}
              ref={backgroundVideoRef}
              onEnded={handleEnd}
              onLoaded={() => {
                setLoading(false);
                onLoadedVideo();
              }}
            />
            {
              loading && (
                <div className="t-foyer_loading">
                  <img src={iconLoading} alt="loading" />
                </div>
              )
            }
            {
              !loading
              && (
                <div className="t-foyer_sound">
                  <SoundControl
                    isMuted={isMuted}
                    handleSound={() => setIsMuted(!isMuted)}
                  />
                </div>
              )
            }
          </div>
        </div>
      </div>
      {openPopup.layerBooth
        && (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {boothIndex === 2 && foyerData?.diemcham[boothIndex].type === 'iframe' ? (
              <>
                <div
                  className="t-foyer_layer t-foyer_layer_game t-foyer_layer_game1"
                  style={returnStyle(0)}
                  onClick={() => setOpenPopup((prev) => ({
                    ...prev, videoPopup: true, gameIndex: 0
                  }))}
                />
                <div
                  className="t-foyer_layer t-foyer_layer_game t-foyer_layer_game2"
                  style={returnStyle(1)}
                  onClick={() => setOpenPopup((prev) => ({
                    ...prev, videoPopup: true, gameIndex: 1
                  }))}
                />
                <div
                  className="t-foyer_layer t-foyer_layer_game t-foyer_layer_game3"
                  style={returnStyle(2)}
                  onClick={() => setOpenPopup((prev) => ({
                    ...prev, videoPopup: true, gameIndex: 2
                  }))}
                />
              </>
            ) : (
              <div
                className={`t-foyer_layer layer${boothIndex}`}
                onClick={() => setOpenPopup((prev) => ({
                  ...prev, videoPopup: true,
                }))}
              />
            )}
          </>
        )}

      <FoyerNoticePopup
        openPopup={openPopup.notice}
        handleClose={() => setOpenPopup((prev) => ({
          ...prev,
          notice: false,
          endFoyer: true,
        }))}
      />
      <PlayerPopup
        isOpen={openPopup.videoPopup}
        handleNext={handleContinueVideo}
        type={foyerData?.diemcham[boothIndex] ? foyerData?.diemcham[boothIndex].type as FoyerDiemChamType : 'img-video'}
        gameIndex={openPopup.gameIndex}
        mediaSrc={{
          image: foyerData?.diemcham[boothIndex]?.srcImg,
          video: foyerData?.diemcham[boothIndex]?.srcVideo,
          images: foyerData?.diemcham[boothIndex]?.srcImages,
          iframe: foyerData?.diemcham[boothIndex]?.srcGame
        }}
      />
      {
        openPopup.endFoyer && <WaitingLivestreamPopup />
      }
    </>
  );
};

Foyer.defaultProps = {
  foyerData: undefined,
  active: undefined,
};

export default Foyer;
