import React from 'react';

import Container from '../Container';

import Icon from 'components/atoms/Icon';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import useDeviceQueries from 'hooks/useDeviceQueries';

interface FooterProps {
}

const Footer: React.FC<FooterProps> = () => {
  const { isMobile } = useDeviceQueries();

  return (
    <footer className="o-footer">
      <Container>
        <div className="o-footer_wrapper">
          <div className="o-footer_bottom">
            <div className="o-footer_contact">
              <div className="o-footer_icon">
                <Icon iconName="supporter" iconSize="34x40" />
              </div>
              <Text modifiers={['24x32', 'white', '400']}>
                Vui lòng liên hệ hotline
                {' '}
                <Link href="tel:0918454049" useExternal>
                  <Text type="span" modifiers={['24x32', 'white', '700']}>
                    0918 45 40 49
                  </Text>
                </Link>
                {' '}
                khi cần hỗ trợ
                {!isMobile && '.'}
                {' '}
                {isMobile && 'trong quá trình đăng ký và tham dự hội thảo.'}
              </Text>
            </div>
            <div className="o-footer_code">
              <Text modifiers={['400', 'white', '18x24', 'right']}>
                Thông tin khoa học dành cho cán bộ y tế
              </Text>
              <Text modifiers={['400', 'white', '18x24', 'right']}>
                ENS-H-402-24
              </Text>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

Footer.defaultProps = {
};

export default Footer;
