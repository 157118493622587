/* eslint-disable  */
import Hls from 'hls.js';
import React, {
  useCallback, useEffect, useImperativeHandle, useRef, useState,
} from 'react';

import icLoading from 'assets/images/loading.gif';
import Icon from 'components/atoms/Icon';
import useMutedAutoplay from 'hooks/useMutedAutoplay';
import Text from 'components/atoms/Text';
import useResumeFg from 'hooks/useResumeFg';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

interface TfcPlayerProps {
  audioLang?: string;
  src: string;
  handleEnd?: () => void;
  handleTimeUpdate?: (time: number) => void;
}

export interface TFCPlayerRef {
  play(): void;
  pause(): void;
}

const TfcPlayer = React.forwardRef<TFCPlayerRef, TfcPlayerProps>(({
  src,
  audioLang = 'vi',
  handleEnd,
  handleTimeUpdate,
}, ref) => {
  const playerRef = useRef<HTMLVideoElement>(null);
  const hlsRef = useRef<Hls>();
  const isLiveEnded = useRef(false);
  const isLoaded = useRef(false);
  const syncDelta = useRef(0);
  const liveSyncDurationCount = useRef(3);

  const [isLive, setIsLive] = useState(false);
  const [isDelay, setIsDelay] = useState(false);
  const [isWating, setIsWating] = useState(false);
  const [isPause, setIsPause] = useState(false);
  const [muted, setMuted] = useState(false);

  useImperativeHandle(ref, () => ({
    play() {
      console.log('TFCPlayer: action play');
      playerRef.current?.play();
    },
    pause() {
      console.log('TFCPlayer: action pause');
      playerRef.current?.pause();
    },
  }), []);

  useEffect(() => {
    const player = playerRef.current;

    if (!player) {
      return () => {
        /* empty */
      };
    }

    if (Hls.isSupported()
      && !(isSafari && player.canPlayType('application/vnd.apple.mpegurl'))
    ) {
      const hls = new Hls({
        liveSyncDurationCount: liveSyncDurationCount.current,
        backBufferLength: 120,
      });
      hls.loadSource(src);
      hls.attachMedia(player);

      hlsRef.current = hls;
      (window as any).hls = hls;

      hls.once(Hls.Events.LEVEL_LOADED, (event, data) => {
        syncDelta.current = liveSyncDurationCount.current > 1
          ? 0.5 * data.details.targetduration
          : 0;
        setIsLive(data.details.live);
      });

      return () => hls.destroy();
    }

    if (player.canPlayType('application/vnd.apple.mpegurl')) {
      player.src = src;
      const handleMeta = () => {
        if (player.duration === Infinity) {
          setIsLive(true);
        }
        player.removeEventListener('loadedmetadata', handleMeta);
      };
      player.addEventListener('loadedmetadata', handleMeta);
    }

    return () => {
      /* empty */
    };

    // Don't allow changing source, easier logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const player = playerRef.current;

    if (!player) {
      return;
    }

    const onLoad = () => {
      console.log('TFCPlayer: onLoadData');
      isLoaded.current = true;

      player.removeEventListener('loadeddata', onLoad);
    };

    player.addEventListener('loadeddata', onLoad);
  }, []);

  useEffect(() => {
    const player = playerRef.current;

    if (!player) {
      return () => {
        /* empty */
      };
    }

    const syncPause = () => setIsPause(player.paused);

    player.addEventListener('loadedmetadata', syncPause, { once: true });
    player.addEventListener('pause', syncPause);
    player.addEventListener('play', syncPause);

    return () => {
      player.removeEventListener('pause', syncPause);
      player.removeEventListener('play', syncPause);
    };
  }, []);

  useMutedAutoplay(playerRef, setMuted);
  useResumeFg(() => {
    console.log('fg resume');
    if (isDelay) {
      syncLive();
    }
  })

  /**
   * change audioLang to default on start-up only
   */
  useEffect(() => {
    const player = playerRef.current;

    if (!player) {
      return () => {
        /* empty */
      };
    }

    const hls = hlsRef.current;
    const { audioTracks } = player as any;

    if (hls) {
      hls.once(Hls.Events.MANIFEST_LOADED, () => {
        hls.audioTrack = Math.max(
          hls.audioTracks.findIndex((t) => t.lang === audioLang),
          0,
        );
      });
    } else if (audioTracks) {
      const onTrackAdd = () => {
        if (audioTracks.length > 1) {
          Array.from(audioTracks).forEach((t: any) => {
            // eslint-disable-next-line no-param-reassign
            t.enabled = t.language === audioLang;
          });
        }
      };

      audioTracks.addEventListener('addtrack', onTrackAdd);

      return () => audioTracks.removeEventListener('addtrack', onTrackAdd);
    }

    return () => {
      /* empty */
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const player = playerRef.current;

    if (!player) {
      return () => {
        /* empty */
      };
    }

    const onWaiting = () => setIsWating(true);
    const onResume = () => setIsWating(false);

    player.addEventListener('waiting', onWaiting);
    player.addEventListener('playing', onResume);
    player.addEventListener('canplay', onResume);

    return () => {
      player.removeEventListener('waiting', onWaiting);
      player.removeEventListener('playing', onResume);
      player.removeEventListener('canplay', onResume);
    };
  }, []);

  useEffect(() => {
    const player = playerRef.current;

    if (!player) {
      return () => {
        /* empty */
      };
    }

    const hls = hlsRef.current;

    const handleSync = () => {
      if (hls) {
        const { liveSyncPosition } = hls;

        if (liveSyncPosition) {
          // TODO optimize throttle
          setIsDelay(player.currentTime < liveSyncPosition - 10);
        }
      } else if (player.duration === Infinity) {
        const end = player.seekable.end(player.seekable.length - 1);
        setIsDelay(player.currentTime < end - 10);
      }
    };

    player.addEventListener('progress', handleSync);
    player.addEventListener('timeupdate', handleSync);
    player.addEventListener('durationchange', handleSync);

    return () => {
      player.removeEventListener('progress', handleSync);
      player.removeEventListener('timeupdate', handleSync);
      player.removeEventListener('durationchange', handleSync);
    };
  }, []);

  useEffect(() => {
    const player = playerRef.current;

    if (!player) {
      return () => {
        /* empty */
      };
    }

    const onDurationChange = () => {
      if (player.paused) {
        console.log('TFCPlayer: resume on network failed!');
        player.play();
        isLiveEnded.current = false;
      }
    };
    player.addEventListener('durationchange', onDurationChange);

    return () => {
      player.removeEventListener('durationchange', onDurationChange);
    };
  }, []);

  useEffect(() => {
    const player = playerRef.current;

    if (!player) {
      return;
    }

    const hls = hlsRef.current;
    const { audioTracks } = player as any;

    if (hls) {
      hls.audioTrack = Math.max(
        hls.audioTracks.findIndex((t) => t.lang === audioLang),
        0,
      );
    } else if (audioTracks) {
      Array.from(audioTracks).forEach((t: any) => {
        // eslint-disable-next-line no-param-reassign
        t.enabled = t.language === audioLang;
      });
    }
  }, [audioLang]);

  const syncLive = useCallback(() => {
    const player = playerRef.current;

    if (!player) {
      return;
    }

    const hls = hlsRef.current;

    if (hls) {
      if (
        hls.levels[1]?.details?.live
        && hls.liveSyncPosition
        && player.currentTime < hls.liveSyncPosition
      ) {
        player.currentTime = hls.liveSyncPosition + syncDelta.current;
        if (player.paused) player.play();
      }
    } else if (player.duration === Infinity) {
      const end = player.seekable.end(player.seekable.length - 1);
      if (player.currentTime < end) {
        player.currentTime = end;
      }
    }
  }, []);

  const onPlayButton = useCallback(() => {
    const player = playerRef.current;

    if (!player) { return; }

    player.muted = false;
    setMuted(false);
    syncLive();
    player.play();
  }, [syncLive]);

  const onEnded = useCallback(() => {
    console.log('TFCPlayer: onEnded');

    isLiveEnded.current = true;

    handleEnd?.();
  }, [handleEnd]);

  return (
    <div className="o-tfcplayer">
      <video
        ref={playerRef}
        style={{ maxWidth: '100%' }}
        muted={muted}
        autoPlay
        loop={false}
        playsInline
        disablePictureInPicture
        disableRemotePlayback
        x-webkit-airplay="deny"
        onEnded={onEnded}
        onTimeUpdate={(event) => handleTimeUpdate
          && handleTimeUpdate(event.currentTarget.currentTime)}
      />
      <div className='o-tfcplayer_layer' />
      {
        isLive &&
        <div
          className="o-tfcplayer_liveIcon"
          onClick={() => {
            if (isDelay) {
              syncLive();
            }
          }}
        >
          <span className={isDelay ? 'delay' : 'islive'}>LIVE</span>
        </div>
      }
      <div
        className="t-livestream_sound"
        onClick={() => setMuted(!muted)}
      >
        <Icon iconName={muted ? 'mute' : 'sound'} iconSize="24x24" />
      </div>
      {isPause && !isWating && (
        <div
          className="t-livestream_playButton"
          onClick={onPlayButton}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="#7b7b7b" width={80} height={80}>
            <path fill="white" d="M10 18a8 8 0 100-16 8 8 0 000 16z" />
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
          </svg>
        </div>
      )}

      {isWating && (
        <div className="o-tfcplayer_loading">
          <img src={icLoading} alt="loading" />
        </div>
      )}
    </div>
  );
});

export default TfcPlayer;
