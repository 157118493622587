import React from 'react';

import Heading from 'components/atoms/Heading';
import mapModifiers from 'utils/functions';

interface BackgroundTitleProps {
  modifier?: 'secondary' | 'survey';
  content?: string;
  children?: React.ReactNode;
}

const BackgroundTitle: React.FC<BackgroundTitleProps> = ({ children, content, modifier }) => (
  <div className={mapModifiers('m-backgroundTitle', modifier)}>
    <div className="m-backgroundTitle_wrap">
      {
        modifier?.includes('secondary') && <div className="m-backgroundTitle_swing" />
      }
      {
        content ? (
          <Heading
            type="h2"
            modifiers={[
              modifier?.includes('survey') ? 'pattrickBlue' : 'white',
              '36x48', '700', 'center']}
            content={content}
          />
        ) : (
          <Heading
            type="h2"
            modifiers={[
              modifier?.includes('survey') ? 'pattrickBlue' : 'white',
              'normal',
              '36x48', '700', 'center']}
          >
            {children}
          </Heading>
        )
      }
    </div>
  </div>
);

BackgroundTitle.defaultProps = {
  modifier: undefined,
};

export default BackgroundTitle;
