import React from 'react';

import qrcode from 'assets/images/qrcode.png';
import Heading from 'components/atoms/Heading';
import Image from 'components/atoms/Image';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';

interface RegisterInstructionProps {
}

const RegisterInstruction: React.FC<RegisterInstructionProps> = () => (
  <div className="t-registerInstruction">
    <div className="t-registerInstruction_heading">
      <div className="t-registerInstruction_headingWrapper">
        <Heading
          type="h2"
          modifiers={['700', 'uppercase', 'center', '34x52']}
        >
          Hướng dẫn đăng ký tham gia
        </Heading>
      </div>
    </div>
    <div className="t-registerInstruction_content">
      <ul>
        <li>
          <Text>
            Truy cập
            {' '}
            <Link target="_blank" href="https://www.hoithaoensure.com/">
              <Text type="span" modifiers={['700', 'pattrickBlue']}>
                www.hoithaoensure.com
              </Text>
            </Link>
            {' '}
            hoặc quét mã QR bên cạnh
          </Text>
          <div className="t-registerInstruction_qrcode tablet-show">
            <Image src={qrcode} alt="qrcode" ratio="1x1" />
          </div>
        </li>
        <li>
          <Text>
            Điền thông tin và nhấn Đăng ký
          </Text>
        </li>
        <li>
          <Text>
            Nhập mã OTP gửi về điện thoại để xác nhận đăng ký thành công
          </Text>
        </li>
        <li>
          <Text>
            Nhận thông tin tham dự qua tin nhắn và email
          </Text>
        </li>
      </ul>
      <div className="t-registerInstruction_qrcode tablet-hide">
        <Image src={qrcode} alt="qrcode" ratio="1x1" />
      </div>
    </div>
  </div>
);

RegisterInstruction.defaultProps = {
};

export default RegisterInstruction;
