import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getInteractiveActivityQuestionService } from 'services/questions';
import { InteractiveItem, SocketInteractiveTypes } from 'services/questions/type';

export type SocketLiveQuestionsTypes = {
  approvedAt: string;
  id: number;
  member: null;
  memberId: number;
  question: string;
  speaker: {
    shortname: string;
    fullname: string;
  },
  toSpeaker: number;
};

interface CurrentQuestion {
  id: number;
  timeBroadcasted: number | null
}

interface QuestionState {
  questions: SocketLiveQuestionsTypes[];
  interactiveList: InteractiveItem[]
  currentQuestion?: CurrentQuestion
}

const initialState: QuestionState = {
  questions: [],
  interactiveList: [],
};

export const getQuestionInteractiveAction = createAsyncThunk<
  InteractiveItem[],
  number,
  { rejectValue: ErrorResponse }
>(
  'configs/getQuestionInteractiveAction',
  async (type, { rejectWithValue }) => {
    try {
      const data = await getInteractiveActivityQuestionService(type);
      return data;
    } catch (error) {
      return rejectWithValue(error as ErrorResponse);
    }
  },
);

export const questionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    setInitQuestions: ($state, action: PayloadAction<SocketLiveQuestionsTypes[]>) => {
      $state.questions = action.payload;
    },
    setQuestions: ($state, action: PayloadAction<SocketLiveQuestionsTypes>) => {
      const clone = [...$state.questions];
      $state.questions = [action.payload, ...clone];
    },
    setInteractives: ($state, action: PayloadAction<{
      timeBroadcasted: number,
      data: SocketInteractiveTypes
    }>) => {
      const { timeBroadcasted, data } = action.payload;
      const clone = $state.interactiveList;
      const findItem = clone.find((item) => item.interactiveActivityQuestion.id === data.id);
      if (findItem) {
        $state.currentQuestion = {
          id: findItem.interactiveActivityQuestion.id,
          timeBroadcasted
        };
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(getQuestionInteractiveAction.fulfilled, (
      $state,
      action: PayloadAction<InteractiveItem[]>
    ) => {
      $state.interactiveList = action.payload;
      const latestIdx = action.payload.reduce((prev, curr, currentIndex) => {
        if (curr.timeBroadcasted && curr.timeBroadcasted >= 0
          && !!curr.interactiveActivityQuestion?.broadcast_at) return currentIndex;
        return prev;
      }, 0);
      if (latestIdx > -1 && action.payload[latestIdx].timeBroadcasted) {
        $state.currentQuestion = {
          id: action.payload[latestIdx].interactiveActivityQuestion?.id,
          timeBroadcasted: action.payload[latestIdx].timeBroadcasted
        };
      }
    });
  },
});

export const {
  setQuestions, setInteractives, setInitQuestions
} = questionSlice.actions;

export default questionSlice.reducer;
