import React, { useMemo } from 'react';

import abbottLogo from 'assets/images/abbott-logo.png';
import bannerDecorMb from 'assets/images/new/home/banner-decorator-mobile.png';
import bannerDecor from 'assets/images/new/home/banner-decorator.png';
import hospenLogo from 'assets/images/new/home/logo-vietspen.png';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import Banner from 'components/templates/Banner';
import useWindowDimensions from 'hooks/useWindowDimension';

const Thanks: React.FC = () => {
  const { width, height } = useWindowDimensions();
  const isHorizontal = useMemo(() => {
    if (width && height) {
      return width < 992 && width > height;
    }
    return false;
  }, [width, height]);
  return (
    <div className="p-thanks">
      <Banner customContent>
        <div className="t-bannerHome_content">
          <div className="t-bannerHome_logo">
            <div className="t-bannerHome_logo_item">
              <Image src={hospenLogo} ratio="342x144" />
            </div>
            <div className="t-bannerHome_logo_item">
              <Image src={abbottLogo} ratio="167x76" />
            </div>
          </div>
          {/* <Text modifiers={['white', 'center', '21x31', 'brandon']}>
            CẢM ƠN QUÝ CHUYÊN VIÊN Y TẾ
            ĐÃ THAM DỰ
          </Text>
          <br />
          <br /> */}
          {/* <div className="t-bannerHome_title">
            <Text modifiers={['white', 'center', '26x39', 'brandon']}>
              HỘI THẢO KHOA HỌC
            </Text>
          </div> */}
          <div className="t-bannerHome_title">
            <Text modifiers={['white', 'center', '26x39', 'brandon']}>
              CẢM ƠN QUÝ CHUYÊN VIÊN Y TẾ
              <br />
              ĐÃ THAM DỰ HỘI THẢO KHOA HỌC
            </Text>
          </div>
          <div className="t-bannerHome_sologan">
            <Text modifiers={['linearGoldNew', 'center', '32x40', '700', 'brandonGrotesque', 'linearShadow']}>
              NÂNG CAO CHĂM SÓC DINH DƯỠNG
            </Text>
          </div>
          <div className="t-bannerHome_sologan">
            <Text modifiers={['linearGoldNew', 'center', '32x40', '700', 'brandonGrotesque', 'linearShadow']}>
              CHO BỆNH NHÂN NGOẠI KHOA
            </Text>
          </div>
          <div className="t-bannerHome_sologan section">
            <Text modifiers={['linearGoldNew', 'center', '32x40', '700', 'brandonGrotesque', 'linearShadow']}>
              NHU CẦU THIẾT THỰC GIÚP HỒI PHỤC SỚM
            </Text>
          </div>
          <div className="t-bannerHome_sologan">
            <Text modifiers={['linearGoldNew', 'center', '32x40', '700', 'brandonGrotesque', 'linearShadow']}>
              SAU PHẪU THUẬT VÀ SAU XUẤT VIỆN
            </Text>
          </div>
          <div className="t-bannerHome_image">
            <Image src={bannerDecor} srcTablet={isHorizontal ? bannerDecor : bannerDecorMb} srcMobile={bannerDecorMb} ratio={isHorizontal ? '1068x1144' : '800x870'} />
          </div>
          {/* <div className="p-thanks_time">
            <Text type="p" modifiers={['white', 'center', '24x32']}>
              SẼ BẮT ĐẦU TRONG ÍT PHÚT NỮA
            </Text>
            <Text type="p" modifiers={['white', 'center', '24x32']}>
              QUÝ CHUYÊN VIÊN Y TẾ VUI LÒNG CHỜ TRONG GIÂY LÁT
            </Text>
          </div> */}
          <div className="p-thanks_time">
            <Text type="p" modifiers={['white', 'center', '24x32']}>
              8:00 - 12:00 | Chủ nhật, 18.08.2024
            </Text>
            <Text type="p" modifiers={['white', 'center', '24x32']}>
              Tại website: www.hoithaoensure.com
            </Text>
          </div>
        </div>
      </Banner>
    </div>
  );
};

export default Thanks;
