import React, { useEffect, useState } from 'react';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import OtpInput from 'components/molecules/OtpInput';
import Popup from 'components/organisms/Popup';

interface OtpPopupProps {
  openPopup: boolean;
  phoneValue?: string;
  error?: string;
  loading?: boolean;
  handleClose?: () => void;
  handleSubmit?: (arg: string) => void;
  handleResend?: () => void;
}

const OtpPopup: React.FC<OtpPopupProps> = ({
  openPopup, phoneValue, handleClose, loading,
  handleSubmit, handleResend, error,
}) => {
  const resendTimer = 120;
  const [otpValue, setOtpValue] = useState('');
  const [inCountdownOTP, setInCountdownOTP] = useState(false);
  const [countdownTimer, setCountdownTimer] = useState(0);

  useEffect(() => {
    if (openPopup) {
      setCountdownTimer(resendTimer);
      setInCountdownOTP(true);
    }
  }, [openPopup]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdownTimer(countdownTimer - 1);
    }, 1000);
    if (countdownTimer === 0) {
      setInCountdownOTP(false);
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [countdownTimer]);

  const handleResendOtp = () => {
    setInCountdownOTP(true);
    setCountdownTimer(resendTimer);
    if (handleResend) handleResend();
  };

  return (
    <div className="t-otpPopup">
      <Popup
        hasCloseButton
        isOpenModal={openPopup}
        title="XÁC THỰC OTP"
        handleClose={handleClose}
        modifiers="otp"
      >
        <Text modifiers={['18x28', '500', 'pattrickBlue', 'center']}>
          Mã OTP đã được gửi tới số điện thoại
          {' '}
          <span>
            <strong>
              {' '}
              {phoneValue}
            </strong>
            .
          </span>
          {' '}
          Xin vui lòng nhập mã OTP để xác thực tài khoản đã đăng ký.
        </Text>
        <OtpInput error={error} length={6} onChangeOTP={(val) => setOtpValue(val)} />
        <div className="t-otpPopup_reset">
          <div className="label">
            <Text modifiers={['18x28', '500', 'pattrickBlue', 'center']} type="span">
              Chưa nhận được mã?
            </Text>
          </div>
          {
            inCountdownOTP
              ? (
                <Text type="span" modifiers={['18x28', '500', 'satinSheenGold', 'center']}>
                  Thử lại sau
                  {' '}
                  {`(${countdownTimer})`}
                </Text>
              )
              : (
                <div
                  className="cursor"
                  onClick={handleResendOtp}
                >
                  <Text modifiers={['18x28', '500', 'satinSheenGold', 'center']} type="span">
                    Gửi lại mã
                  </Text>
                </div>
              )
          }

        </div>
        <div className="t-otpPopup_button">
          <Button
            loading={loading}
            size="h44"
            disabled={otpValue.length < 6}
            onClick={() => otpValue !== '' && handleSubmit && handleSubmit(otpValue)}
          >
            XÁC THỰC
          </Button>
        </div>
      </Popup>
    </div>
  );
};

OtpPopup.defaultProps = {
  phoneValue: undefined,
  error: undefined,
  handleClose: undefined,
  handleSubmit: undefined,
  handleResend: undefined,
  loading: undefined,
};

export default OtpPopup;
