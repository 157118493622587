import React, { useEffect, useMemo, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import DeviceWrapped from 'components/templates/DeviceOriented';
import Survey, { SurveyFinished, SurveyReady } from 'components/templates/Survey';
// import eventConfig from 'configs';
import getSurveyQuestionsService from 'services/survey';
import { SurveyQuestionDataTypes } from 'services/survey/type';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMemberProfileAction } from 'store/profile';
import mapModifiers from 'utils/functions';

const SurveyContainer: React.FC = () => {
  const [step, setStep] = useState(0);
  const [surveyQuestions, setSurveyQuestion] = useState<SurveyQuestionDataTypes[]>([]);
  const { data: memberInfo } = useAppSelector((state) => state.profile);
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // if (memberInfo?.allowCme) {
    //   navigate(eventConfig.SLUG_PARAMS.CME);
    //   return;
    // }
    if (memberInfo?.allowSurvey === false) {
      setStep(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberInfo]);

  /**
   * Modify step classname
   */
  const stepClass = useMemo(() => {
    switch (step) {
      case 1:
        return 'progress';
      case 2:
        return 'done';
      default:
        return 'start';
    }
  }, [step]);

  useEffect(() => {
    (async () => {
      const res = await getSurveyQuestionsService();
      setSurveyQuestion(res);
    })();
  }, []);

  if (!memberInfo) {
    return <>Loading...</>;
  }
  return (
    <DeviceWrapped>
      <div className={mapModifiers('p-survey', stepClass)}>
        {(() => {
          switch (step) {
            case 1:
              return (
                <Survey
                  surveyQuestions={surveyQuestions}
                  handleNextStep={(stepVal) => {
                    dispatch(getMemberProfileAction());
                    setStep(stepVal);
                  }}
                />
              );
            case 2:
              return <SurveyFinished />;

            default:
              return (
                <SurveyReady handleClick={() => {
                  dispatch(getMemberProfileAction());
                  setStep(1);
                }}
                />
              );
          }
        })()}
        <ToastContainer />
      </div>
    </DeviceWrapped>
  );
};

export default SurveyContainer;
