import {
  InteractiveItem,
  QuestionDataTypes, QuestionInteractiveGuestTypesParams,
  QuestionInteractiveTypesParams, QuestionParamsTypes
} from './type';

import axiosInstance from 'services/common/instance';

const getQuestionServices = async (): Promise<QuestionDataTypes[]> => {
  const res = await axiosInstance.get('questions');
  return res.data.data;
};

export const postQuestionLivestreamService = async (params: QuestionParamsTypes): Promise<void> => {
  const res = await axiosInstance.post('questions', params);
  return res.data;
};

export const postQuestionInteractiveService = async (
  params: QuestionInteractiveTypesParams
): Promise<void> => {
  await axiosInstance.post('members/update-interactive-exams', params);
};

export const postQuestionInteractiveForGuestService = async (
  params: QuestionInteractiveGuestTypesParams
): Promise<void> => {
  await axiosInstance.post('members/update-interactive-exams/for-guest', params);
};

export const getInteractiveActivityQuestionService = async (type: number):
  Promise<InteractiveItem[]> => {
  const res = await axiosInstance.get(`interactive-exams?type=${type}`);
  return res.data.data;
};

export default getQuestionServices;
