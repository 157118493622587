import React from 'react';

import imagePopup from 'assets/images/foyer-popup.jpg';
import Image from 'components/atoms/Image';
import Popup from 'components/organisms/Popup';

interface FoyerNoticePopupProps {
  openPopup: boolean;
  handleClose?: () => void;
}

const FoyerNoticePopup: React.FC<FoyerNoticePopupProps> = ({
  openPopup, handleClose,
}) => (
  <div className="t-foyerNoticePopup">
    <Popup
      isOpenModal={openPopup}
      modifiers="noticeFoyer"
    >
      <div onClick={handleClose} style={{ cursor: 'pointer' }}>
        <Image src={imagePopup} ratio="2048x1152" />
      </div>
    </Popup>
  </div>
);

FoyerNoticePopup.defaultProps = {
  handleClose: undefined,
};

export default FoyerNoticePopup;
