import 'App.scss';

import React from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router, Navigate, Route, RouteProps, Routes
} from 'react-router-dom';

import eventConfig from 'configs';
import MainLayoutContainer from 'containers/mainLayout';
import AppInitializer from 'helpers/appinitializer';
import Cme from 'pages/Cme';
import EventWaiting from 'pages/EventWaiting';
import Foyer from 'pages/Foyer';
import Home from 'pages/Home';
import InteractiveOffline from 'pages/InteractiveOffline';
import Livestream from 'pages/Livestream';
import Login from 'pages/Login';
import Survey from 'pages/Survey';
import Thanks from 'pages/Thanks';
import { getTokenSession } from 'services/common/storage';
import { store } from 'store';

const routes = [
  {
    paths: '/',
    element: <Home />,
    isAuth: false,
  },
  {
    paths: eventConfig.SLUG_PARAMS.LOGIN,
    element: <Login />,
    isAuth: false,
  },
  // {
  //   paths: eventConfig.SLUG_PARAMS.WAITING,
  //   element: <Waiting />,
  //   isAuth: true,
  // },
  {
    paths: eventConfig.SLUG_PARAMS.EVENTWAITING,
    element: <EventWaiting />,
    isAuth: false,
  },
  {
    paths: eventConfig.SLUG_PARAMS.LIVESTREAM,
    element: <Livestream />,
    isAuth: true,
  },
  {
    paths: eventConfig.SLUG_PARAMS.SURVEY,
    element: <Survey />,
    isAuth: true,
  },
  {
    paths: eventConfig.SLUG_PARAMS.CME,
    element: <Cme />,
    isAuth: true,
  },
  {
    paths: eventConfig.SLUG_PARAMS.FOYER,
    element: <Foyer />,
    isAuth: true,
  },
  {
    paths: eventConfig.SLUG_PARAMS.THANKS,
    element: <Thanks />,
    isAuth: false,
  },
];

interface PrivateRouteProps extends RouteProps {
  outlet: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ outlet }) => {
  const token = getTokenSession();
  if (!token) return <Navigate to={eventConfig.SLUG_PARAMS.LOGIN} />;
  return outlet;
};

const App: React.FC = () => (
  <Routes>
    <Route
      element={(
        <MainLayoutContainer />
      )}
    >
      {
        routes.map((item, index) => (item.isAuth ? (
          <Route
            path={item.paths}
            key={`route-${index.toString()}`}
            element={(
              <PrivateRoute
                outlet={item.element}
              />
            )}
          />
        ) : (
          <Route
            key={`router-${item.element}-${index.toString()}`}
            path={item.paths}
            element={item.element}
          />
        )))
      }
    </Route>
    <Route path="/dongthuan-hn" element={<InteractiveOffline type={1} />} />
    <Route path="/dongthuan-hcm" element={<InteractiveOffline type={2} />} />
  </Routes>
);
const AppWrapper: React.FC = () => (
  <Provider store={store}>
    <Router>
      <AppInitializer />
      <App />
    </Router>
  </Provider>
);

export default AppWrapper;
