import React from 'react';

import Heading from 'components/atoms/Heading';
import Text from 'components/atoms/Text';
import Container from 'components/organisms/Container';

export type AgendaTypes = {
  time: string;
  subTitle?: string;
  title: string;
  speaker?: string[];
  classSubTitle?: string;
};
interface AgendaProps {
  agendaData: AgendaTypes[];
  agendaData2: AgendaTypes[];
}

const AgendaItem: React.FC<AgendaTypes> = ({
  time, title, subTitle, speaker, classSubTitle
}) => (
  <div className="t-agenda_item">
    <div className="t-agenda_item_datetime">
      <Text modifiers={['darkCerulean', '700', '18x28']}>
        {time}
      </Text>
    </div>
    <div className="t-agenda_item_content">
      <div className="t-agenda_item_title">
        <Text modifiers={['darkCerulean', '700', '21x31']}>
          {title}
        </Text>
        {subTitle && (
          <div className={'t-agenda_item_subTitle' + `${classSubTitle ? ` ${classSubTitle}` : ''}`}>
            <Text modifiers={['700', '18x28', 'uppercase']}>
              {subTitle}
            </Text>
          </div>
        )}
      </div>
      {
        speaker && speaker.length
        && (
          <div className="t-agenda_item_speaker">
            {speaker.map((item, idx) => (
              <Text key={`speaker-${idx.toString()}`} modifiers={['500', '21x31', 'italic', 'gray']}>
                {item}
              </Text>
            ))}
          </div>
        )
      }
    </div>
  </div>
);

const Agenda: React.FC<AgendaProps> = ({
  agendaData,
  agendaData2,
}) => (
  <div className="t-agenda">
    <Container>
      <div className="t-agenda_list">
        <div className="t-agenda_heading">
          <div className="t-agenda_heading_wrap">
            <Heading
              type="h2"
              modifiers={['700', 'uppercase', 'center', '40x56', 'darkCerulean']}
            >
              NỘI DUNG CHƯƠNG TRÌNH
            </Heading>
          </div>
        </div>
        <div className="t-agenda_list_wrap">
          <div className="t-agenda_listItem t-agenda_left">
            {
              agendaData.map((val, idx) => (
                <AgendaItem {...val} key={`item-${idx.toString()}`} />
              ))
            }
          </div>
          <div className="t-agenda_listItem t-agenda_right">
            {
              agendaData2.map((val, idx) => (
                <AgendaItem {...val} key={`item-${idx.toString()}`} />
              ))
            }
          </div>
        </div>
      </div>
    </Container>
  </div>
);

Agenda.defaultProps = {
};

export default Agenda;
