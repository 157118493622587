import React from 'react';
import DatePicker from 'react-datepicker';

import { datePickerLocalized } from './helpers';

import 'react-datepicker/dist/react-datepicker.css';
import Icon, { IconName } from 'components/atoms/Icon';
import { InputReadOnly } from 'components/atoms/Input';
import Pulldown, { OptionType } from 'components/molecules/Pulldown';
import mapModifiers, {
  convertLocalToUTCDate, getMonth, months, years,
} from 'utils/functions';

type DatePickerModifiers = 'verify' | 'infoModal' | 'borderRadiusColor' | 'editable';
interface DatepickerProps {
  startDate?: Date;
  handleChangeDate: (date: Date) => void;
  id: string;
  label?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  modifiers?: DatePickerModifiers | DatePickerModifiers[];
  innerRef?: React.Ref<HTMLDivElement>;
  theme?: 'light' | 'dark';
  size?: 'md' | 'sm';
  placeholder?: string;
  iconLabel?: IconName;
  locale?: string;
}

const Datepicker: React.FC<DatepickerProps> = ({
  startDate,
  handleChangeDate,
  label,
  id,
  error,
  required,
  disabled,
  modifiers,
  innerRef,
  theme,
  size,
  placeholder,
  iconLabel,
  locale,
}) => (
  <div
    className={mapModifiers(
      'm-datePicker',
      modifiers,
      error && 'error',
      disabled && 'disabled',
      theme,
      size,
    )}
  >
    {label && (
      <label className="m-datePicker_label" htmlFor={id}>
        {iconLabel && (
          <span className="m-datePicker_label-icon">
            <Icon iconName={iconLabel} />
          </span>
        )}
        {label}
        {required && <span className="m-datePicker_label-required">*</span>}
      </label>
    )}
    <DatePicker
      id={id}
      locale={datePickerLocalized(locale)}
      className="m-datePicker_wrapper"
      wrapperClassName="m-datePicker_container"
      popperClassName="m-datePicker_popper"
      calendarClassName="m-datePicker_calendar"
      showPopperArrow={false}
      placeholderText={placeholder}
      selected={startDate}
      onChange={(date: Date) => handleChangeDate(convertLocalToUTCDate(date))}
      dateFormat="dd/MM/yyyy"
      // onKeyDown={(e) => e.preventDefault()}
      customInput={(
        <InputReadOnly
          id="test"
          modifiers="h44"
          className="a-input_inputele"
          placeholder={placeholder}
        />
      )}
      renderCustomHeader={({ date, changeYear, changeMonth }) => (
        <div className="m-datePicker_header" ref={innerRef}>
          <Pulldown
            name="year"
            placeholder={String(date.getFullYear())}
            value={{ label: String(date.getFullYear()), value: String(date.getFullYear()) }}
            options={years}
            modifier="datepicker"
            onChange={(data) => {
              const dataValue = data as OptionType;
              changeYear(Number(dataValue.label));
            }}
          />
          <Pulldown
            modifier="datepicker"
            name="month"
            placeholder={String(months[getMonth(date)].label)}
            options={months}
            value={{
              label: String(months[getMonth(date)].label),
              value: String(months[getMonth(date)].label),
            }}
            onChange={(data) => {
              const dataValue = data as OptionType;
              changeMonth(months.findIndex((item) => item.label === dataValue.label));
            }}
          />
        </div>
      )}
    />
    {error && <span className={mapModifiers('a-input_errorMessage', 'error')}>{error}</span>}
  </div>
);

Datepicker.defaultProps = {
  theme: 'light',
  size: 'md',
};

export default Datepicker;
