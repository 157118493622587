import React from 'react';

import HomeContainer from 'containers/home';

const Home: React.FC = () => (
  <div className="p-home">
    <HomeContainer />
  </div>
);

export default Home;
