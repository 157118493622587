import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  useCallback,
  useEffect, useMemo, useState
} from 'react';
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';

import { OptionType } from 'components/molecules/Pulldown';
import { LiveStreamQuestionType } from 'components/organisms/LiveComment';
import FinishLivePopup from 'components/templates/FinishLivePopup';
import Livestream, { QuestionForm } from 'components/templates/Livestream';
import { useAsync } from 'hooks/useAsync';
import useResumeFg from 'hooks/useResumeFg';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'services/common/storage';
import getQuestionServices, { postQuestionLivestreamService } from 'services/questions';
import { QuestionParamsTypes } from 'services/questions/type';
import trackingOnlineService from 'services/tracking';
import { TrackingOnlineDataTypes } from 'services/tracking/type';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  SocketLiveQuestionsTypes, setInitQuestions
} from 'store/questions';
import { LIVE_URL } from 'utils/constant';
import { getDateTimeTracking, getTimeLocate } from 'utils/functions';
import { speakerSchema } from 'utils/schemas';

const initialValuesSpeaker = {
  speaker: null,
  question: '',
};

const LivestreamContainer: React.FC = () => {
  // States
  const [openPopup, setOpenPopup] = useState(false);
  const [liveQuestions, setLiveQuestions] = useState<LiveStreamQuestionType[]>([]);
  const [doctorOptions, setDoctorOptions] = useState<OptionType[]>([]);
  const [refreshKey, setRefreshKey] = useState(0);

  // Store

  const dispatch = useAppDispatch();
  const { data: systemData } = useAppSelector((state) => state.system);
  const endedLivestream = useAppSelector((state) => state.system.endedLivestream);
  const { questions: questionsLive } = useAppSelector((state) => state.questions);

  // React-hook-form
  const method = useForm<QuestionForm>({
    mode: 'onSubmit',
    defaultValues: {
      speaker: undefined,
      question: '',
    },
    resolver: yupResolver(speakerSchema),
  });

  // Hooks
  const urlState = useMemo(() => (!openPopup ? LIVE_URL : ''), [openPopup]);

  /**
   * Set Doctor Options
   */
  useEffect(() => {
    if (systemData) {
      const res = Object.keys(systemData.speakers).map((val) => (
        {
          value: val,
          label: systemData.speakers[Number(val) as number].fullname,
        }
      ));
      setDoctorOptions(res || []);
    }
  }, [systemData]);

  const fetchQuestions = useCallback(async () => {
    const res = await getQuestionServices();
    const questions: SocketLiveQuestionsTypes[] = res.map((val) => ({
      approvedAt: val.approvedAt,
      id: val.id,
      memberId: val.memberId,
      question: val.question,
      speaker: val.speaker,
      toSpeaker: val.toSpeaker,
      member: val.member,
    }));
    dispatch(setInitQuestions(questions));
    // dispatch(getQuestionInteractiveAction());
  }, [dispatch]);

  /**
   * Get livestream Questions
   */
  useEffect(() => {
    fetchQuestions();
    // dispatch(getQuestionInteractiveAction());
  }, [dispatch, fetchQuestions]);

  useResumeFg(fetchQuestions);

  /**
     * Submit question on livestream
     */
  const [postQuestion, questionLiveState] = useAsync(
    async (params: QuestionParamsTypes) => {
      await postQuestionLivestreamService(params);
    },
    {
      onSuccess: () => {
        method.reset(initialValuesSpeaker);
        toast.success('Gửi câu hỏi thành công !!', {
          position: 'top-right',
          autoClose: 3000,
        });
      },
      onFailed: () => {
        toast.error('Gửi câu hỏi thất bại !!', {
          position: 'top-right',
          autoClose: 3000,
        });
      },
    }
  );
  const handleEndLive = useCallback(() => {
    setOpenPopup(true);
    setRefreshKey(refreshKey + 1);
  }, [refreshKey]);
  /**
   * Submit Questions
   */
  const handleSubmit = (data: QuestionForm) => {
    if (data.question === '' || !data.speaker) return;
    postQuestion({
      to_speaker: Number(data.speaker.value),
      question: data.question,
    });
  };

  /**
   *  TRACKING ONLINE
   */
  const sendTracking = async (data: TrackingOnlineDataTypes[]) => {
    if (!data) return;
    try {
      const minuteSaved = getLocalStorage('currentWatchLive');
      const res = await trackingOnlineService({
        data_tracking: data,
        current_minutes_watched: minuteSaved ? parseInt(minuteSaved, 10) : undefined,
      });
      if (res.liveEnded) {
        setOpenPopup(true);
        setRefreshKey((prev) => prev + 1);
      }
    } catch {
      // TODO: error
    }
  };

  useEffect(() => {
    let counter = 0;
    const trackingSaved = getLocalStorage('trackingData');
    const trackingArr: TrackingOnlineDataTypes[] = trackingSaved
      ? JSON.parse(trackingSaved)
      : [];

    const interval = setInterval(() => {
      counter += 1;
      if (counter === 3) {
        trackingArr.push({ zone: 2, time_tracking: getDateTimeTracking() });
        sendTracking(trackingArr);
        counter = 0;
        trackingArr.splice(0, trackingArr.length);
      } else {
        trackingArr.push({ zone: 2, time_tracking: getDateTimeTracking() });
        setLocalStorage('trackingData', JSON.stringify(trackingArr));
      }
    }, 60000);
    if (openPopup) {
      clearInterval(interval);
    }
    return () => {
      removeLocalStorage('trackingData');
      clearInterval(interval);
    };
  }, [openPopup]);

  /**
   * SOCKET LIVE QUESTIONS
   */

  useEffect(() => {
    setLiveQuestions(questionsLive.map((dataSocket) => ({
      id: dataSocket.id,
      question: dataSocket.question,
      speaker: dataSocket.speaker.fullname,
      approvedAt: getTimeLocate(new Date(dataSocket.approvedAt)),
    })));
  }, [questionsLive]);

  useEffect(() => {
    if (endedLivestream) {
      setOpenPopup(true);
      setRefreshKey((prev) => prev + 1);
    }
  }, [endedLivestream]);

  /**
   * End live
   */
  const questionList = useMemo(() => liveQuestions.reduce((p: LiveStreamQuestionType[], c) => (
    p.some((s) => s.id === c.id) ? p : [...p, c]
  ), []), [liveQuestions]);

  return (
    <>
      <Livestream
        method={method}
        speakerOptions={doctorOptions}
        liveQuestions={questionList}
        handleSubmit={handleSubmit}
        isLoading={questionLiveState.loading}
        handleEndLive={handleEndLive}
        liveUrl={urlState}
        key={refreshKey}
      />

      {/* <FoyerNoticePopup
        openPopup={openIntroPopup}
        handleClose={() => setOpenIntroPopup(false)}
      /> */}

      <FinishLivePopup
        openPopup={openPopup}
      // handleSurvey={() => {
      //   setTimeout(() => {
      //     navigate(eventConfigs.SLUG_PARAMS.SURVEY);
      //   }, 300);
      // }}
      // handleCme={() => navigate(eventConfigs.SLUG_PARAMS.CME)}
      />
      <ToastContainer />
    </>
  );
};

export default LivestreamContainer;
