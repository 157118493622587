import React from 'react';

import LoginTemplate from 'components/templates/Login';
import EventWaiting from 'pages/EventWaiting';
import { useAppSelector } from 'store/hooks';

const Login: React.FC = () => {
  const dataConfig = useAppSelector((state) => state.system.data);
  const userInfo = useAppSelector((state) => state.profile.data);
  const userLogin = useAppSelector((state) => state.user.data);
  return (
    <div className="p-login">
      {dataConfig?.redirectToPage === 'WAITING_2' && (userInfo || userLogin) ? <EventWaiting /> : <LoginTemplate />}
    </div>
  );
};

export default Login;
