import { MemberProfileTypes } from './type';

import axiosInstance from 'services/common/instance';

const getMemberProfileService = async (): Promise<MemberProfileTypes> => {
  const res = await axiosInstance.get('members/profile');
  return res.data.data;
};

export default getMemberProfileService;
