import * as yup from 'yup';

export const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

const registerSchema = yup.object().shape({
  invitation_code: yup.string().required('Mã thư mời là bắt buộc !'),
  full_name: yup
    .string()
    .required('Họ và tên là bắt buộc !')
    .max(255, 'Nhập tối đa 255 ký tự'),
  company_address: yup
    .string()
    .required('Địa chỉ nơi công tác là bắt buộc !'),
  specialist: yup
    .string()
    .required('Chuyên khoa là bắt buộc !'),
  email: yup
    .string()
    .email('Địa chỉ email không hợp lệ !')
    .required('Email là bắt buộc')
    .max(255, 'Nhập tối đa 255 ký tự'),
  phone: yup
    .string()
    .required('Số điện thoại là bắt buộc !')
    .min(8, 'Số điện thoại phải có ít nhất 8 ký tự là số')
    .max(20, 'Số điện thoại tối đa 20 ký tự là số')
    .matches(phoneRegExp, 'Số điện thoại không hợp lệ'),
});

export const certificateSchema = yup.object().shape({
  certification_fullname: yup.string().required('Họ và tên là bắt buộc'),
  certification_company_address: yup.string().required('Nơi công tác là bắt buộc'),
  certification_birthday: yup.string().nullable().required('Ngày sinh là bắt buộc'),
  certification_birthplace: yup.string().nullable().required('Nơi sinh là bắt buộc'),
  certificate_gender: yup.object().required('Hãy chọn giới tính'),
  certificate_position: yup.string().required('Chức danh là bắt buộc'),
});

export const speakerSchema = yup.object().shape({
  speaker: yup.object().nullable().required('Xin hãy chọn diễn giả'),
});

export default registerSchema;
