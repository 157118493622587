import React from 'react';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import Popup from 'components/organisms/Popup';

interface PolicyPopupProps {
  isOpen: boolean;
  handleClose?: () => void;
  handleOK?: () => void;
}

const PolicyPopup: React.FC<PolicyPopupProps> = ({
  isOpen, handleClose, handleOK
}) => (
  <Popup
    hasCloseButton
    isOpenModal={isOpen}
    title="TÔI ĐỒNG Ý CHO ABBOTT LABORAToRIES GMBH (*ABBOTT) LƯU TRỮ VÀ SỬ DỤNG CÁC THÔNG TIN ĐÃ CUNG CẤP Ở TRÊN"
    modifiers="policy"
    sub="Chi tiết điều khoản"
    handleClose={handleClose}
  >
    <div className="o-popup_wrapContent">
      <div className="o-popup_content">
        <Text modifiers={['18x28', 'pattrickBlue']}>
          Bằng việc đăng ký tham dự chương trình qua website
          www.hoithaoensure.com, cung cấp thông tin cá nhân cơ bản và
          thông tin cá nhân nhạy cảm và/hoặc nhấn vào nút “Tôi đồng ý với
          điều khoản này” bạn xác nhận mình đã đọc và hiểu Chính sách
          quyền riêng tư của Abbott tại https://www.vn.abbott/privacy-policy.html
          và cho phép Công ty TNHH Dinh Dưỡng 3A
          (Việt Nam) và Văn Phòng Đại Diện Abbott Laboratories GmbH tại
          Thành phố Hồ Chí Minh (Abbott) xử lý toàn bộ các thông tin
          cá nhân này, bao gồm nhưng không giới hạn ở việc thu thập, ghi,
          phân tích, xác nhận, lưu trữ, chỉnh sửa, công khai, kết hợp,
          tích hợp, truy cập, truy xuất, thu hồi, mã hóa, giải mã, sao chép,
          xóa, hủy, chia sẻ, truyền đưa, cung cấp, chuyển giao cho các công
          ty trong cùng tập đoàn Abbott và/hoặc các nhà cung cấp dịch vụ
          bên thứ ba được chỉ định, trong hoặc ngoài lãnh thổ Việt Nam, và
          xử lý thông tin cá nhân bằng phương tiện tự động, nhằm mục đích
          lập danh sách khách hàng/tiến hành khảo sát/tiếp nhận, quản lý và
          hỗ trợ khách hàng/thống kê/đánh giá/tuân thủ với quy định pháp
          luật, ví dụ như đăng ký khuyến mại/tư vấn chăm sóc sức khoẻ dinh
          dưỡng và/hoặc cung cấp thông tin (thông tin về các sự kiện, lời
          mời tham dự hội thảo, thông tin nghiên cứu khoa học, kiến thức
          chăm sóc sức khoẻ, thông tin sản phẩm, khuyến mại, sự kiện và
          thông tin tiếp thị và phi tiếp thị khác)/ký kết và thực hiện hợp
          đồng/thực hiện thanh toán, phục vụ quá trình giải quyết tranh
          chấp, khiếu nại, tuân thủ các quy định pháp luật liên quan, tập hợp
          dữ liệu từ các nguồn khác nhau vào cơ sở dữ liệu tập trung, và
          thực hiện các biện pháp bảo vệ thông tin cá nhân của bạn, tùy
          trường hợp cần thiết. Abbott cam kết đảm bảo các biện pháp
          quản lý và kỹ thuật cần thiết để bảo vệ thông tin cá nhân mà bạn
          cung cấp, phù hợp với Chính sách quyền riêng tư của Abbott và
          pháp luật Việt Nam. Việc xử lý dữ liệu sẽ được bắt đầu kể từ thời
          điểm Abbott nhận được dữ liệu cá nhân và sự đồng ý của bạn cho
          việc xử lý dữ liệu cá nhân đó. Sự đồng ý của bạn có hiệu lực cho
          tới khi bạn có quyết định khác.
          <br />
          Bạn có quyền: yêu cầu chỉnh sửa, rút lại sự đồng ý, xóa, hạn chế,
          yêu cầu cung cấp dữ liệu cá nhân của mình. Bạn có nghĩa vụ: Tự
          bảo vệ; cung cấp đầy đủ, chính xác dữ liệu cá nhân của mình.
          Để thực hiện các quyền trên, vui lòng liên hệ số điện thoại
          1900 1519
        </Text>
      </div>
    </div>
    <div className="o-popup_button">
      <Button
        size="h44"
        onClick={handleOK}
      >
        TÔI ĐỒNG Ý VỚI ĐIỀU KHOẢN NÀY
      </Button>
    </div>
  </Popup>
);

export default PolicyPopup;
