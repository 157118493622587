import React from 'react';

import DeviceWrapped from 'components/templates/DeviceOriented';
import LivestreamContainer from 'containers/livestream';
import { useDidMount } from 'helpers/react-hook';
import { getLivestreamInteractivesAction } from 'store/configs';
import { useAppDispatch } from 'store/hooks';

const Livestream: React.FC = () => {
  const dispatch = useAppDispatch();
  useDidMount(() => {
    dispatch(getLivestreamInteractivesAction());
  });
  return (
    <DeviceWrapped>
      <div className="p-livestream">
        <LivestreamContainer />
      </div>
    </DeviceWrapped>
  );
};

export default Livestream;
