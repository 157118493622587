import React, { useMemo } from 'react';

import abbottLogo from 'assets/images/abbott-logo.png';
import bannerMobileImg from 'assets/images/new/home/banner-mb.jpg';
import bannerImg from 'assets/images/new/home/banner.jpg';
import hospenLogo from 'assets/images/new/home/logo-vietspen.png';
import Image from 'components/atoms/Image';
import useWindowDimensions from 'hooks/useWindowDimension';
import mapModifiers from 'utils/functions';

interface BannerProps {
  customContent?: boolean;
  isCenter?: boolean;
  children?: React.ReactNode;
  bannerText?: {
    mobile: string;
    desktop: string;
  }
}

const Banner: React.FC<BannerProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  customContent, isCenter, children, bannerText
}) => {
  const { width, height } = useWindowDimensions();
  const isHorizontal = useMemo(() => {
    if (width && height) {
      return width < 992 && width > height;
    }
    return false;
  }, [width, height]);

  return (
    <div className={mapModifiers('t-banner', customContent && 'custom', isCenter && 'isCenter', isHorizontal && 'isHorizontal')}>
      {/* Background  */}
      <div className="t-banner_background">
        <Image src={bannerImg} srcTablet={isHorizontal ? bannerImg : bannerMobileImg} srcMobile={bannerMobileImg} ratio="1366x768" />
      </div>
      {
        !customContent ? (
          <div className="t-banner_wrapper">
            <div className="t-banner_relative">
              {/* Logo  */}
              <div className="t-banner_logo">
                <div className="t-banner_logo_item">
                  <Image src={hospenLogo} ratio="342x144" />
                </div>
                <div className="t-banner_logo_item">
                  <Image src={abbottLogo} ratio="167x76" />
                </div>
              </div>
              {children}
            </div>
          </div>
        ) : (
          <div className="t-banner_contentCustom">
            {children}
          </div>
        )
      }
    </div>
  );
};

Banner.defaultProps = {
  customContent: undefined,
};

export default Banner;
