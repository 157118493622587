/* eslint-disable jsx-a11y/media-has-caption */
import React, { forwardRef } from 'react';

import useWindowSize from 'hooks/useWindowSize';

export interface PlayerProps {
  id?: string;
  src: string;
  isLoop?: boolean;
  isMuted?: boolean;
  autoplay?: boolean;
  isScreen?: boolean;
  onEnded?: () => void;
  onTimeUpdate?: (time: number) => void;
  onWaiting?: () => void;
  onPlaying?: () => void;
  onLoaded?: () => void;
}

const Player = forwardRef<HTMLVideoElement, PlayerProps>(({
  src, autoplay, id, isLoop, isMuted, isScreen,
  onEnded, onTimeUpdate, onWaiting, onPlaying, onLoaded,
}, ref) => {
  const { width, height } = useWindowSize();
  return (
    <div className="o-player">
      <video
        id={id}
        ref={ref}
        style={isScreen ? {
          maxWidth: `${width}px`,
          maxHeight: `${height}px`,
        } : {
          width: '100%',
          height: '100%',
        }}
        controls={false}
        playsInline
        loop={isLoop}
        muted={isMuted}
        onEnded={onEnded}
        autoPlay={autoplay}
        onTimeUpdate={(event) => onTimeUpdate && onTimeUpdate(event.currentTarget.currentTime)}
        onWaiting={onWaiting}
        onPlaying={onPlaying}
        onLoadedMetadata={onLoaded}
        data-wf-ignore="true"
        data-object-fit="cover"
        src={src}
        onContextMenu={(e) => { e.preventDefault(); }}
      />
      <div className="o-player_layer" />
    </div>
  );
});

export default Player;
