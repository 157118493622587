const config = {
  tokenField: 'ES24_TOKEN',
} as const;

interface TokenSessionPayload {
  accessToken: string;
}

export const setLocalStorage = (name: string, value: string) => {
  window.localStorage.setItem(name, value);
};

export const getLocalStorage = (name: string): string | null => window.localStorage.getItem(name);

export const removeLocalStorage = (name: string) => {
  window.localStorage.removeItem(name);
};

export const clearLocalStorage = () => {
  window.localStorage.clear();
};

export const initialTokenSession = (payload: TokenSessionPayload) => {
  const { accessToken } = payload;

  localStorage.setItem(config.tokenField, accessToken);
};

export const getTokenSession = (): Partial<TokenSessionPayload> | false => {
  const accessToken = localStorage.getItem(config.tokenField);

  if (!accessToken) return false;
  return { accessToken };
};

export const clearTokenSession = () => {
  localStorage.removeItem(config.tokenField);
};
