/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Modal from 'react-modal';

import Text from 'components/atoms/Text';
import mapModifiers from 'utils/functions';

interface PopupProps {
  isOpenModal: boolean;
  shouldCloseOnOverlayClick?: boolean;
  title?: React.ReactNode;
  sub?: React.ReactNode;
  useBackgroundImage?: boolean;
  modifiers?: string;
  style?: React.CSSProperties;
  hasCloseButton?: boolean;
  handleClose?: () => void;
  children?: React.ReactNode;

}

const Popup: React.FC<PopupProps> = ({
  children,
  title,
  style,
  sub,
  isOpenModal,
  modifiers,
  hasCloseButton,
  handleClose,
  useBackgroundImage,
  shouldCloseOnOverlayClick,
}) => {
  Modal.setAppElement('#root');
  return (
    <Modal
      style={{
        content: style,
      }}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      isOpen={isOpenModal}
      onRequestClose={handleClose}
      contentLabel="Example Modal"
      htmlOpenClassName="reactmodal-html-open"
      className={mapModifiers(
        'o-popup',
        modifiers,
        useBackgroundImage && 'usebackgroundimage',
      )}
    >
      <div className="o-popup_body">
        {hasCloseButton && (
          <div aria-hidden className="o-popup_close" onClick={handleClose} />
        )}
        <div className="o-popup_wrapcontent">
          {title && (
            <div className="o-popup_heading">
              <div className="o-popup_title">
                <span>{title}</span>
              </div>
              {sub && (
                <div className="o-popup_sub">
                  <Text modifiers={['18x28', 'yaleBlue']}>
                    {sub}
                  </Text>
                </div>
              )}
            </div>
          )}
          {children}
        </div>
      </div>
    </Modal>
  );
};

Popup.defaultProps = {
  shouldCloseOnOverlayClick: undefined,
  title: '',
  sub: '',
  useBackgroundImage: undefined,
  modifiers: undefined,
  hasCloseButton: undefined,
  handleClose: undefined,

};

export default Popup;
