/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import Form from 'components/organisms/Form';
import { forgotPasswordService } from 'services/auth';
import { ForgotPasswordParamsTypes } from 'services/auth/type';
import { cleanRegionPhoneNumber } from 'utils/functions';

interface ForgotPasswordFormProps {
  handleSubmitForm?: (phone: string) => void;
  handleTogglePopup?: (name: string) => void
}

const ForgotPasswordFormTemplate:
  React.FC<ForgotPasswordFormProps> = ({ handleSubmitForm, handleTogglePopup }) => {
    const phoneRegExp = /(84|[3|5|7|8|9])+([0-9]{8})\b/g;
    const [loadingButton, setLoadingButton] = useState(false);

    const schema = yup.object().shape({
      phone_number: yup
        .string()
        .required('Số điện thoại là bắt buộc !')
        .min(8, 'Số điện thoại phải có ít nhất 8 ký tự là số')
        .max(20, 'Số điện thoại tối đa 20 ký tự là số')
        .matches(phoneRegExp, 'Số điện thoại không hợp lệ'),
    });
    const method = useForm<ForgotPasswordParamsTypes>({
      resolver: yupResolver(schema),
    });
    const handleSubmitForgotPass = async (data: ForgotPasswordParamsTypes) => {
      setLoadingButton(true);
      const phoneRegionFormat = `+84${cleanRegionPhoneNumber(data.phone_number)}`;

      try {
        await forgotPasswordService({
          phone_number: phoneRegionFormat,
        });
        if (handleSubmitForm) handleSubmitForm(data.phone_number);
      } catch (error) {
        if (Array.isArray(error)) {
          error.forEach((err) => {
            method.setError(err.field, { message: err.message });
          });
        }
      }
      setLoadingButton(false);
    };
    return (
      <div className="t-forgotPasswordForm">
        <div className="t-forgotPasswordForm_subTitle">
          <Text modifiers={['darkSlateBlue', '20x32']}>
            Xác nhận số điện thoại và chúng tôi sẽ gửi mật khẩu khôi phục.
          </Text>
        </div>
        <div className="t-forgotPasswordForm_form">
          <Form method={method} submitForm={handleSubmitForgotPass}>
            <div className="t-forgotPasswordForm_input">
              <Controller
                control={method.control}
                name="phone_number"
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    id="phone_number"
                    prefix="(+84)"
                    value={value}
                    placeholder="Nhập số điện thoại"
                    type="text"
                    modifiers={['border', 'h52']}
                    error={error?.message}
                    handleChange={onChange}
                  />
                )}
              />
            </div>
            <div className="t-forgotPasswordForm_button">
              <Button
                size="h44"
                loading={loadingButton}
                isSubmit
              >
                Khôi phục mật khẩu
              </Button>
            </div>
            <div className="t-forgotPasswordForm_wrapRegister">
              <Text type="span" modifiers={['24x32', 'pattrickBlue']}>
                Chưa có tài khoản?
              </Text>
              <div
                className="t-forgotPasswordForm_register"
                onClick={() => handleTogglePopup && handleTogglePopup('registerPopup')}
              >
                <Text type="span" modifiers={['700', '24x32']}>
                  Đăng ký ngay
                </Text>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  };
ForgotPasswordFormTemplate.defaultProps = {
  handleSubmitForm: undefined,
};

export default ForgotPasswordFormTemplate;
