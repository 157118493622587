/* eslint-disable no-console */
import { useEffect } from 'react';

function useMutedAutoplay(
  playerRef: React.RefObject<HTMLVideoElement>,
  callback?: (muted: boolean) => void,
  dept?: any,
  isNotAuto?: boolean,

) {
  useEffect(() => {
    const player = playerRef.current;

    if (!player || isNotAuto) {
      return;
    }

    player.addEventListener('loadedmetadata', () => player
      .play()
      .then(() => {
        console.log('Autoplay: unmuted');
      })
      .catch(async () => {
        player.muted = true;
        callback?.(true);

        await player.play();
        console.log('Autoplay: muted');
      })
      .catch((err) => {
        console.log('Autoplay: deined', err);
      }), { once: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerRef, dept]);
}
export default useMutedAutoplay;
