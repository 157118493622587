import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import getMemberProfileService from 'services/member';
import { MemberProfileTypes } from 'services/member/type';

interface ProfileState {
  data?: MemberProfileTypes;
}

const initialState: ProfileState = {
  data: undefined,
};

export const getMemberProfileAction = createAsyncThunk<
  MemberProfileTypes,
  void,
  { rejectValue: ErrorResponse }
>('profile/getMemberProfileAction', async (_, { rejectWithValue }) => {
  try {
    const res = await getMemberProfileService();
    return res;
  } catch (error) {
    return rejectWithValue(error as ErrorResponse);
  }
});

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getMemberProfileAction.fulfilled, ($state, action) => {
      $state.data = action.payload;
    });
  },
});

export default profileSlice.reducer;
