import React from 'react';

import BackgroundPanel from '../BackgroundPanel';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import { useAppDispatch } from 'store/hooks';
import { getSystemAction } from 'store/system';

interface FinishLivePopupProps {
  openPopup: boolean;
  // handleCme?: () => void;
  handleSurvey?: () => void;
}

const FinishLivePopup: React.FC<FinishLivePopupProps> = (
  { openPopup, handleSurvey },
) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const refreshGeneralSystem = (func: () => void) => {
    setLoading(true);
    dispatch(getSystemAction()).unwrap().then(() => func()).finally(() => setLoading(false));
  };
  return (
    <div>
      {openPopup && (
        <BackgroundPanel title="Kết Thúc Hội Thảo" modifier="endlive">
          <div>
            <Text modifiers={['pattrickBlue', 'center', '24x32', '500']}>
              Cám ơn Quý Chuyên viên Y Tế đã tham dự chương trình
              Hội thảo khoa học và đào tạo y khoa liên tục.
              <br />
              Quý vị hãy tiếp tục tham gia kiểm tra kiến thức
              nhận chứng chỉ CME và đóng góp ý kiến cho chương trình
            </Text>
          </div>
          <div className="t-finishLive_button">
            <div className="t-finishLive_button_item">
              <Button
                size="h44"
                loading={loading}
                onClick={() => {
                  refreshGeneralSystem(() => handleSurvey && handleSurvey());
                }}
              >
                KHẢO SÁT Ý KIẾN
              </Button>
            </div>
            {
              handleSurvey
              && (
                <div className="t-finishLive_button_item">
                  <Button
                    loading={loading}
                    size="h44"
                    onClick={() => {
                      refreshGeneralSystem(() => handleSurvey());
                    }}
                  >
                    Khảo sát ý kiến
                  </Button>
                </div>
              )
            }
          </div>
        </BackgroundPanel>
      )}
    </div>
  );
};

export default FinishLivePopup;
