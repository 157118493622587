import React from 'react';

import DeviceWrapped from 'components/templates/DeviceOriented';
import FoyerContainer from 'containers/foyer';

const Foyer: React.FC = () => (
  <div className="p-foyer">
    <DeviceWrapped>
      <FoyerContainer />
    </DeviceWrapped>
  </div>
);

export default Foyer;
