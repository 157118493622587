/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-danger */
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';

import Text from 'components/atoms/Text';
import useResumeFg from 'hooks/useResumeFg';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'services/common/storage';
import { postQuestionInteractiveService } from 'services/questions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getQuestionInteractiveAction } from 'store/questions';
import { LOCALSTORAGE_FIELDNAME, TIME_DELAY_INTERACTIVE, TOTAL_TIME_INTERACTIVE } from 'utils/constant';
import mapModifiers from 'utils/functions';

interface InteractiveAnswerProps {
  id: number;
  alpha: string;
  text: string;
  corrected?: boolean;
  selected?: boolean;
  handleSelected?: (id: number) => void;
}

export const InteractiveAnswer: React.FC<InteractiveAnswerProps> = ({
  id, alpha, text, handleSelected, selected, corrected,
}) => (
  <div
    className="t-livestream_interactive_answer"
    onClick={() => handleSelected && handleSelected(id)}
  >
    <div className={mapModifiers('t-livestream_interactive_answer_content', selected && 'selected', corrected && 'corrected')}>
      <div className="t-livestream_interactive_answer_alpha">
        <Text modifiers={['white', '700', 'center', 'brandonGrotesque']}>
          <span>{alpha}</span>
        </Text>
      </div>
      <div className={mapModifiers('t-livestream_interactive_answer_text', selected && 'selected', corrected && 'corrected')}>
        <Text modifiers={['usBlue', '700', 'brandonGrotesque']}>
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </Text>
      </div>
    </div>
  </div>
);

const Interactive: React.FC = () => {
  const dispatch = useAppDispatch();
  const { interactiveList, currentQuestion } = useAppSelector((state) => state.questions);
  const [seconds, setSeconds] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(
    getLocalStorage(LOCALSTORAGE_FIELDNAME.INTERACTIVE_ANSWER)
      ? Number(getLocalStorage(LOCALSTORAGE_FIELDNAME.INTERACTIVE_ANSWER)) : null
  );

  const currentQuestionData = useMemo(() => (interactiveList.find((
    item
  ) => item.interactiveActivityQuestion.id
    === currentQuestion?.id)), [currentQuestion, interactiveList]);

  const handleSelectInteractive = async (questionId: number, answerId: number) => {
    try {
      await postQuestionInteractiveService({ question_id: questionId, answer_id: answerId });
    } catch {
      // empty
    }
  };
  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          }
          removeLocalStorage(LOCALSTORAGE_FIELDNAME.INTERACTIVE_ANSWER);
          clearInterval(interval);
          return 0;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
    return () => { };
  }, [seconds]);

  useEffect(() => {
    if (seconds === 0) {
      setSelectedAnswer(null);
      removeLocalStorage(LOCALSTORAGE_FIELDNAME.INTERACTIVE_ANSWER);
      const timeout = setTimeout(() => {
        setOpenPopup(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
    return () => { };
  }, [seconds]);

  useEffect(() => {
    if (!currentQuestion) {
      setOpenPopup(false);
      return () => { };
    }
    // const currentInteractive = interactives[interactives.length - 1];
    // setCurrent(currentInteractive);
    if (currentQuestion.timeBroadcasted === null) {
      return () => { };
    }

    if (currentQuestion.timeBroadcasted > (TOTAL_TIME_INTERACTIVE + TIME_DELAY_INTERACTIVE)) {
      setSeconds(0);
    } else if (currentQuestion.timeBroadcasted < (TOTAL_TIME_INTERACTIVE + TIME_DELAY_INTERACTIVE)
      && currentQuestion.timeBroadcasted >= TIME_DELAY_INTERACTIVE) {
      setSeconds((TOTAL_TIME_INTERACTIVE + TIME_DELAY_INTERACTIVE)
        - currentQuestion.timeBroadcasted);
      setOpenPopup(true);
    } else if (currentQuestion.timeBroadcasted < TIME_DELAY_INTERACTIVE) {
      const timeout = setTimeout(() => {
        setSeconds(TOTAL_TIME_INTERACTIVE);
        setOpenPopup(true);
      }, (TIME_DELAY_INTERACTIVE - currentQuestion.timeBroadcasted) * 1000);
      return () => clearTimeout(timeout);
    }
    return () => { };
  }, [currentQuestion]);

  useEffect(() => {
    dispatch(getQuestionInteractiveAction(2));
  }, [dispatch]);

  useResumeFg(() => {
    dispatch(getQuestionInteractiveAction(2));
  });

  if (!openPopup) {
    return null;
  }
  return (
    <div className="t-livestream_interactive">
      <div className="t-livestream_interactive_header">
        <div className="t-livestream_interactive_countdown">
          <div className="t-livestream_interactive_countdown-text">
            THỜI
            <br />
            GIAN
          </div>
          <div className="t-livestream_interactive_countdown-line" />
          <div className="t-livestream_interactive_countdown-number">
            {seconds < 10 ? `0${seconds}` : seconds}
          </div>
        </div>
        <div className="t-livestream_interactive_note">
          <Text modifiers={['white', '16x22', 'brandonGrotesque']}>
            Thông tin khoa học dành cho cán bộ y tế
            <br />
            ENS-H-087-24
          </Text>
        </div>
      </div>
      {currentQuestionData && (
        <>
          <div className="t-livestream_interactive_question">
            <div className="t-livestream_interactive_question-title">
              <Text modifiers={['usBlue', '700', 'center', 'brandonGrotesque']}>
                Câu hỏi
                {' '}
                {currentQuestionData.interactiveActivityQuestion?.display_order}
              </Text>
            </div>
            <Text modifiers={['usBlue', '700', 'center', 'brandonGrotesque']}>
              <span dangerouslySetInnerHTML={{ __html: currentQuestionData.interactiveActivityQuestion?.content || '' }} />
            </Text>
          </div>
          <div className="t-livestream_interactive_answers">
            {currentQuestionData.interactiveActivityQuestion?.answers.map((answer, index) => (
              <InteractiveAnswer
                key={answer.id}
                id={answer.id}
                alpha={String.fromCharCode(index + 97).toUpperCase()}
                text={answer.content}
                selected={seconds > 0 && selectedAnswer === answer.id}
                corrected={seconds === 0 && answer.is_correct === 1}
                handleSelected={(id) => {
                  if (seconds > 0) {
                    setSelectedAnswer(id);
                    setLocalStorage(LOCALSTORAGE_FIELDNAME.INTERACTIVE_ANSWER, id.toString());
                    if (currentQuestion) {
                      handleSelectInteractive(currentQuestion.id, id);
                    }
                  }
                }}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Interactive;
