/* eslint-disable react/no-unused-prop-types */
import React from 'react';

import Heading from 'components/atoms/Heading';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';

export type TestimonialsDataType = {
  id?: number;
  img: string;
  name: string;
  role: string;
  position: string[];
};
interface TestimonialsProps {
  dataTestimonials: TestimonialsDataType[];
  dataTestimonials2: TestimonialsDataType[];
}

const TestimonialsItem: React.FC<TestimonialsDataType> = ({
  img,
  name,
  role,
  position,
}) => (
  <div className="t-testimonials_item">
    <div className="t-testimonials_item_inner">
      <div className="t-testimonials_item_wrapImg">
        <div className="t-testimonials_item_imgLayer" />
        <div className="t-testimonials_item_img">
          <Image src={img} alt={name} ratio="288x312" />
        </div>
      </div>
      <div className="mt-3">
        <div className="t-testimonials_item_role">
          <Text modifiers={['20x28', 'center', 'pattrickBlue', '500']}>
            {role}
          </Text>
        </div>
        <div className="t-testimonials_item_name">
          <Text modifiers={['900', '24x36', 'center', 'uppercase']}>
            {name}
          </Text>
        </div>
        <div className="t-testimonials_item_position">
          {position.map((val, idx) => (
            <Text
              modifiers={['19x30', 'center', 'darkCerulean', '400']}
              key={`item${idx.toString()}`}
            >
              {val}
            </Text>
          ))}
        </div>
      </div>
    </div>
  </div>
);
const TestimonialsTemplate: React.FC<TestimonialsProps> = (
  {
    dataTestimonials,
    dataTestimonials2
  }
) => (
  <div className="t-testimonials">
    <div className="t-testimonials_heading">
      <Heading
        type="h2"
        modifiers={['700', 'uppercase', 'center', '40x56']}
      >
        VỚI SỰ THAM GIA CỦA CÁC CHUYÊN GIA
      </Heading>
    </div>
    <div className="t-testimonials_containerFirst">
      <div className="t-testimonials_title">
        <Text modifiers={['28x40', 'center', 'pattrickBlue', '700']}>
          CHỦ TỌA
        </Text>
      </div>
      <div className="t-testimonials_wrapper">
        {dataTestimonials.map((val, idx) => (
          <TestimonialsItem
            key={`item${idx.toString()}`}
            {...val}
          />
        ))}
      </div>
    </div>
    <div className="t-testimonials_containerSecond">
      <div className="t-testimonials_title">
        <Text modifiers={['28x40', 'center', 'pattrickBlue', '700']}>
          BÁO CÁO VIÊN
        </Text>
      </div>
      <div className="t-testimonials_wrapper">
        {dataTestimonials2.map((val, idx) => (
          <TestimonialsItem
            key={`item${idx.toString()}`}
            {...val}
          />
        ))}
      </div>
    </div>
  </div>
);

export default TestimonialsTemplate;
