/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import eventConfigs from '../configs';

import { clearLocalStorage, clearTokenSession, getTokenSession } from 'services/common/storage';
import { useAppSelector } from 'store/hooks';

export type KeyRoute = '/' | 'FOYER' | 'LIVESTREAM' | 'WAITING_1' | 'WAITING_2' | 'CME' | 'THANK_YOU' | 'CLOSED' | 'REGISTER' | 'SURVEY';

export type KeyMe = 'FOYER' | 'LIVESTREAM' | 'WAITING_1' | 'WAITING_2' | 'CME' | 'THANK_YOU';

export const returnPath = (code: KeyRoute): string => {
  switch (code) {
    case 'LIVESTREAM':
      return eventConfigs.SLUG_PARAMS.LIVESTREAM;
    case 'FOYER':
      return eventConfigs.SLUG_PARAMS.FOYER;
    case 'WAITING_1':
      return eventConfigs.SLUG_PARAMS.LOGIN;
    case 'WAITING_2':
      return eventConfigs.SLUG_PARAMS.LOGIN;
    case 'CME':
      return eventConfigs.SLUG_PARAMS.CME;
    case 'SURVEY':
      return eventConfigs.SLUG_PARAMS.SURVEY;
    case 'REGISTER':
      return eventConfigs.SLUG_PARAMS.HOME;
    case 'CLOSED':
      return eventConfigs.SLUG_PARAMS.THANKS;
    default:
      return '/';
  }
};
export const returnKeyRoute = (path: string): string => {
  switch (path) {
    case eventConfigs.SLUG_PARAMS.LIVESTREAM:
      return 'LIVESTREAM';
    case eventConfigs.SLUG_PARAMS.FOYER:
      return 'FOYER';
    case eventConfigs.SLUG_PARAMS.LOGIN:
      return 'WAITING_2';
    case eventConfigs.SLUG_PARAMS.CME:
      return 'CME';
    case eventConfigs.SLUG_PARAMS.SURVEY:
      return 'SURVEY';
    case eventConfigs.SLUG_PARAMS.THANKS:
      return 'CLOSED';
    case eventConfigs.SLUG_PARAMS.HOME:
      return 'REGISTER';
    default:
      return '/';
  }
};

const RedirectToPage: React.FC = () => {
  const token = getTokenSession();
  const location = useLocation();
  const navigate = useNavigate();
  const dataConfig = useAppSelector((state) => state.system.data);

  useEffect(() => {
    if (token && location.pathname === '/logout') {
      clearTokenSession();
      navigate(eventConfigs.SLUG_PARAMS.LOGIN);
      clearLocalStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, location.pathname]);
  const ignoreRoute = ['REGISTER'];

  useEffect(() => {
    if (((location.pathname === '/survey' || location.pathname === '/thanks') && (dataConfig && dataConfig.redirectToPage === 'CME'))
      || (location.pathname === '/cme' || location.pathname === '/thanks') && (dataConfig && dataConfig.redirectToPage === 'SURVEY')) {
      navigate(location.pathname);
    } else if (dataConfig) {
      if (dataConfig.redirectToPage) {
        const curToken = getTokenSession();
        const shouldLogin = !curToken && location.pathname === eventConfigs.SLUG_PARAMS.LOGIN && !ignoreRoute.includes(dataConfig.redirectToPage);
        const index = dataConfig.redirectToPage.indexOf(returnKeyRoute(location.pathname));

        if (index < 0 && !shouldLogin) {
          navigate(returnPath(dataConfig.redirectToPage as KeyRoute));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataConfig, location.pathname]);

  return null;
};

export default RedirectToPage;
