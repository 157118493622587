import { CmeAnswerParamsTypes, CmeQuestionDataTypes, UpdateCertificateCmeParams } from './type';

import axiosInstance from 'services/common/instance';

const getCmeQuestionService = async (): Promise<CmeQuestionDataTypes[]> => {
  const res = await axiosInstance.get('exam-questions');
  return res.data.data;
};

export const postCmeExamService = async (params: CmeAnswerParamsTypes) => {
  const res = await axiosInstance.post('members/update-certificate-exam', params);
  return res.data;
};
export const updateCertificateCmeService = async (
  params: UpdateCertificateCmeParams,
): Promise<void> => { await axiosInstance.post('members/update-certificate', params); };

export default getCmeQuestionService;
