import React, { useCallback, useState } from 'react';

import SingleOTPInput from './singleOtp';

import Text from 'components/atoms/Text';

export interface OtpInputProps {
  length: number;
  error?: string;
  onChangeOTP: (otp: string) => any;
}

const OtpInput: React.FC<OtpInputProps> = ({ error, length, onChangeOTP }) => {
  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(Array<string>(length).fill(''));

  // Helper to return OTP from inputs
  const handleOtpChange = useCallback(
    (otp: string[]) => {
      const otpValue = otp.join('');
      onChangeOTP(otpValue);
    },
    [onChangeOTP],
  );

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || '';
      setOTPValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues],
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length],
  );

  const focusPrevInput = useCallback(() => focusInput(activeInput - 1), [activeInput, focusInput]);
  const focusNextInput = useCallback(() => focusInput(activeInput + 1), [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index: number) => () => focusInput(index),
    [focusInput],
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.currentTarget.value;
      if (!val) return;
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput],
  );

  // Handle onBlur input
  const onBlur = useCallback(() => setActiveInput(-1), []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const pressedKey = e.key;
      switch (pressedKey) {
        case 'Backspace':
        case 'Delete': {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus('');
          } else {
            focusPrevInput();
          }
          break;
        }
        case 'ArrowLeft': {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case 'ArrowRight': {
          e.preventDefault();
          focusNextInput();
          break;
        }
        default: {
          if (pressedKey.match(/^[^a-zA-Z0-9]$/)) {
            e.preventDefault();
          }
          break;
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues],
  );

  const handleOnPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData('text/plain')
        .trim()
        .slice(0, length - activeInput)
        .split('');
      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        updatedOTPValues.forEach((_, index) => {
          if (index >= activeInput) {
            const changedValue = pastedData.shift();
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setOTPValues(updatedOTPValues);
        handleOtpChange(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeInput, length, otpValues],
  );

  return (
    <div className={`m-otpInput${error ? ' error' : ''}`}>
      <div className="m-otpInput_wrapper">
        {Array(length)
          .fill('')
          .map((_, index) => (
            <SingleOTPInput
              id={`singleInput${index + 1}`}
              key={`singleInput-${index.toString()}`}
              type="number"
              autoFocus
              focus={activeInput === index}
              value={otpValues && otpValues[index]}
              handleFocus={handleOnFocus(index)}
              handleChange={handleOnChange}
              handleBlur={onBlur}
              onKeyDown={handleOnKeyDown}
              onPaste={handleOnPaste}
            />
          ))}
      </div>
      {error && (
        <div className="m-otpInput_error">
          <Text modifiers={['18x28', '500', 'center']}>
            {error}
          </Text>
        </div>
      )}
    </div>
  );
};

OtpInput.defaultProps = {
};

export default OtpInput;
