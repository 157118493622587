/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';

import Foyer from 'components/templates/Foyer';
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage
} from 'services/common/storage';
import trackingOnlineService, { activityTrackingService } from 'services/tracking';
import { TrackingOnlineDataTypes } from 'services/tracking/type';
import { getConfigsFoyerAction } from 'store/configs';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getDateTimeTracking } from 'utils/functions';

const FoyerContainer: React.FC = () => {
  const { foyer: foyerConfigs } = useAppSelector((state) => state.configs);
  const { data: systemData } = useAppSelector((state) => state.system);
  const dispatch = useAppDispatch();

  /**
   *  TRACKING ONLINE
   */
  const sendTracking = async (data: TrackingOnlineDataTypes[]) => {
    if (!data) return;
    try {
      const minuteSaved = getLocalStorage('currentWatchLive');
      await trackingOnlineService({
        data_tracking: data,
        current_minutes_watched: minuteSaved
          ? parseInt(minuteSaved, 10)
          : undefined,
      });
    } catch {
      // TODO: error
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     await activityTrackingService({
  //       stepNumber: 1,
  //       stepDescription: 'startFoyer'
  //     });
  //   })();
  // }, []);

  useEffect(() => {
    dispatch(getConfigsFoyerAction());
    let counter = 0;
    const trackingSaved = getLocalStorage('trackingData');
    const trackingArr: TrackingOnlineDataTypes[] = trackingSaved
      ? JSON.parse(trackingSaved)
      : [];

    const interval = setInterval(() => {
      counter += 1;
      if (counter === 3) {
        trackingArr.push({ zone: 1, time_tracking: getDateTimeTracking() });
        sendTracking(trackingArr);
        counter = 0;
        trackingArr.splice(0, trackingArr.length);
      } else {
        trackingArr.push({ zone: 1, time_tracking: getDateTimeTracking() });
        setLocalStorage('trackingData', JSON.stringify(trackingArr));
      }
    }, 60000);
    return () => {
      removeLocalStorage('trackingData');
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!foyerConfigs
    || systemData?.redirectToPage !== 'FOYER') { return <p>Loading...</p>; }

  return <Foyer foyerData={foyerConfigs} active />;
};

export default FoyerContainer;
