import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import icMute from 'assets/icons/ic_mute_blue.png';
import icUnMute from 'assets/icons/ic_unmute_blue.png';
import background from 'assets/images/bg-popup-video.png';
import ditiep from 'assets/images/ditiep.png';
import xemlai from 'assets/images/xemlai.png';
import xemtiep from 'assets/images/xemtiep.png';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import Player from 'components/organisms/Player';
import SimiPopup from 'components/organisms/SimiPopup';
import useDidMount from 'hooks/useDidMount';
import useMutedAutoplay from 'hooks/useMutedAutoplay';
import useWindowDimensions from 'hooks/useWindowDimension';
import { FoyerDiemChamType } from 'services/configs/type';
import postGameScoreService from 'services/game';
import { GameParamsTypes } from 'services/game/types';

interface PlayerPopupProps {
  isOpen: boolean;
  gameIndex: number;
  mediaSrc: {
    video?: string;
    image?: string;
    images?: string[];
    iframe?: string[];
  };
  type?: FoyerDiemChamType;
  handleNext?: () => void;
}

const PlayerPopup: React.FC<PlayerPopupProps> = ({
  isOpen, mediaSrc, type = 'img-video', handleNext, gameIndex
}) => {
  const videoPopupRef = useRef<HTMLVideoElement>(null);
  const sliderRef = useRef<Slider>(null);
  const { width, height } = useWindowDimensions();

  // States
  const [isMuted, setIsMuted] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [slideIdx, setSlideIdx] = useState(0);
  useMutedAutoplay(videoPopupRef, setIsMuted, isOpen);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const saveGameScore = async (scoreData: GameParamsTypes) => {
    try {
      await postGameScoreService(scoreData);
    } catch {
      // empty
    }
  };

  useDidMount(() => {
    const processMessage = (message: MessageEvent<any>) => {
      if (message.data.action === 'GAME_STORE') {
        saveGameScore({
          game_id: 1,
          game_name: message.data.game_code,
          score: message.data.score,
          result: message.data.time
        });
      }
      if (message.data.action === 'GO_TO_NEXT' && handleNext) {
        saveGameScore({
          game_id: 1,
          game_name: message.data.game_code,
          score: message.data.score,
          result: message.data.time
        });
        handleNext();
      }
    };
    window.addEventListener('message', processMessage);

    return () => window.removeEventListener('message', processMessage);
  });

  // useEffect(() => {
  //   if (type !== 'iframe') return;
  //   let gameStatus = '';
  //   setInterval(() => {
  //     gameStatus = localStorage.getItem('endGame') || '';
  //     if (gameStatus === 'done' && handleNext) {
  //       handleNext();
  //       localStorage.removeItem('endGame');
  //     }
  //   }, 1000);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [type]);

  useEffect(() => {
    if (isOpen) {
      setShowButton(false);
    }
  }, [isOpen]);

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current: number) => {
      setSlideIdx(current);
    }
  };

  const renderContent = () => {
    switch (type) {
      case 'images':
        return (
          <div className="t-playerPopup_image">
            <Slider {...settings} ref={sliderRef}>
              {
                mediaSrc.images?.map((image) => (
                  <div key={image}>
                    <img src={image} alt="background" />
                  </div>
                ))
              }
            </Slider>
          </div>
        );
      case 'image':
        return (
          <Image src={mediaSrc.image || ''} alt="background" ratio="2480x1063" />
        );
      case 'iframe':
        return (
          <div className="t-playerPopup_iframe">
            <iframe src={mediaSrc.iframe ? mediaSrc.iframe[gameIndex] : ''} title="game" style={{ width: `${width}px`, height: `${height}px` }} />
          </div>
        );
      case 'video':
        return (
          <>
            {/* <Image src={background} alt="background" ratio="popupVideo" /> */}
            <div className="t-playerPopup_content">
              <Player
                ref={videoPopupRef}
                src={mediaSrc.video || ''}
                isMuted={isMuted}
                autoplay
                onEnded={() => setShowButton(true)}
              />
              <div className="t-playerPopup_controls">
                <div
                  className={`t-playerPopup_speaker${isMuted ? ' muted' : ''}`}
                  onClick={() => setIsMuted(!isMuted)}
                >
                  <div className="wrap">
                    <img
                      src={isMuted ? icMute : icUnMute}
                      alt="mute"
                    />
                    <p>BẬT ÂM THANH</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        );

      default:
        return (
          <div className="t-playerPopup_video">
            {
              showVideo ? (
                <>
                  <Image src={background} alt="background" ratio="popupVideo" />
                  <div className="t-playerPopup_content">
                    <Player
                      ref={videoPopupRef}
                      src={mediaSrc.video || ''}
                      isMuted={isMuted}
                      autoplay
                      onEnded={() => setShowButton(true)}
                    />
                    <div className="t-playerPopup_controls">
                      <div
                        className={`t-playerPopup_speaker${isMuted ? ' muted' : ''}`}
                        onClick={() => setIsMuted(!isMuted)}
                      >
                        <div className="wrap">
                          <img
                            src={isMuted ? icMute : icUnMute}
                            alt="mute"
                          />
                          <p>BẬT ÂM THANH</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <Image src={mediaSrc.image || ''} alt="background" ratio="3543x2835" />
              )
            }

          </div>
        );
    }
  };
  return (
    <div className="t-playerPopup">
      <SimiPopup
        isOpenPopup={isOpen}
        handleButtonClose={handleNext}
        modifiers={`player ${type}`}
      >
        {renderContent()}
      </SimiPopup>
      {
        isOpen && type === 'video' && showButton
        && (
          <>
            <div className="t-playerPopup_review">
              <Button
                onClick={() => {
                  if (videoPopupRef.current) {
                    videoPopupRef.current.currentTime = 0;
                    videoPopupRef.current.play();
                    // setIsEnded(false);
                  }
                }}
              >
                <img src={xemlai} alt="mute" />
              </Button>
            </div>
            <div className="t-playerPopup_next">
              <Button
                onClick={() => {
                  // setIsEnded(false);
                  setShowVideo(false);
                  setIsMuted(false);
                  if (handleNext) {
                    handleNext();
                  }
                }}
              >
                <img src={ditiep} alt="" />
              </Button>
            </div>
          </>
        )
      }
      {isOpen && type === 'img-video' && !showVideo && (
        <div className="t-playerPopup_img_next">
          <Button
            onClick={() => setShowVideo(true)}
          >
            <img src={ditiep} alt="" />
          </Button>
        </div>
      )}
      {isOpen && type === 'image' && (
        <div className="t-playerPopup_img_next">
          <Button
            onClick={() => {
              if (handleNext) {
                handleNext();
              }
            }}
          >
            <img src={ditiep} alt="" />
          </Button>
        </div>
      )}
      {isOpen && type === 'images' && (
        <div className="t-playerPopup_img_next">
          <Button
            onClick={() => {
              if (mediaSrc.images && slideIdx === mediaSrc.images.length - 1) {
                if (handleNext) {
                  handleNext();
                  setSlideIdx(0);
                }
              } else {
                sliderRef.current?.slickNext();
              }
            }}
          >
            <img src={mediaSrc.images && slideIdx === mediaSrc.images.length - 1 ? ditiep : xemtiep} alt="" />
          </Button>
        </div>
      )}
    </div>
  );
};

PlayerPopup.defaultProps = {
  handleNext: undefined,
  type: undefined,
};

export default PlayerPopup;
