import React from 'react';

import Popup from 'components/organisms/Popup';
import mapModifiers from 'utils/functions';

interface BackgroundPanelProps {
  title?: string;
  noScreen?: boolean;
  modifier?: 'secondary' | 'firstCME' | 'resultCme' | 'firstSurvey' | 'endlive' | 'endSurvey' | 'formCertificate';
  classname?: string;
  children?: React.ReactNode;
}

const BackgroundPanel: React.FC<BackgroundPanelProps> = ({
  title, children, noScreen, modifier, classname,
}) => (
  <div className="t-backgroundPanel_wrap">
    <Popup
      isOpenModal
      title={title}
      modifiers={`popupBackground${` ${modifier}` || ''}`}
    >
      <div className={classname}>
        <div className={mapModifiers('t-backgroundPanel', modifier, noScreen ? 'noScreen' : '')}>
          <div className="t-backgroundPanel_content">
            {children}
          </div>
        </div>
      </div>
    </Popup>
  </div>
);

BackgroundPanel.defaultProps = {
  title: undefined,
  noScreen: undefined,
  modifier: undefined,
  classname: undefined,
};

export default BackgroundPanel;
