import React, { useMemo } from 'react';

import abbottLogo from 'assets/images/abbott-logo.png';
import bannerDecorMb from 'assets/images/new/home/banner-decorator-mobile.png';
import bannerDecor from 'assets/images/new/home/banner-decorator.png';
import hospenLogo from 'assets/images/new/home/logo-vietspen.png';
import step1 from 'assets/images/new/home/step1.png';
import step2 from 'assets/images/new/home/step2.png';
import step3 from 'assets/images/new/home/step3.png';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import Countdown from 'components/molecules/Countdown';
import useWindowDimensions from 'hooks/useWindowDimension';
import mapModifiers from 'utils/functions';

interface BannerHomeProps {
  eventStart: string;
  handleClick?: () => void;
}

export const BannerContent: React.FC<{ notFit?: boolean }> = ({ notFit }) => (
  <>
    <div className="t-bannerHome_title">
      <Text modifiers={['white', 'center', '26x39', '700', 'brandon']}>
        HỘI THẢO KHOA HỌC
      </Text>
    </div>
    <div className="t-bannerHome_sologan">
      <Text modifiers={['linearGoldNew', 'center', '30x48', '700']}>
        NÂNG CAO CHĂM SÓC DINH DƯỠNG CHO BỆNH NHÂN NGOẠI KHOA
        <br />
        NHU CẦU THIẾT THỰC GIÚP HỒI PHỤC SỚM SAU PHẪU THUẬT VÀ SAU XUẤT VIỆN
      </Text>
    </div>
    <div className={mapModifiers('t-bannerHome_steps', notFit && 'notFit')}>
      <div className="t-bannerHome_step">
        <Image src={step1} alt="Trước phẩu thuật" ratio="46x37" />
      </div>
      <div className="t-bannerHome_step">
        <Image src={step2} alt="Sau phẩu thuật" ratio="46x37" />
      </div>
      <div className="t-bannerHome_step">
        <Image src={step3} alt="Sau xuất viện" ratio="46x37" />
      </div>
    </div>
  </>
);

const BannerHome: React.FC<BannerHomeProps> = ({
  eventStart,
  handleClick,
}) => {
  const { width, height } = useWindowDimensions();
  const isHorizontal = useMemo(() => {
    if (width && height) {
      return width < 992 && width > height;
    }
    return false;
  }, [width, height]);
  return (
    <div className="t-bannerHome">
      <div className="t-bannerHome_content">
        <div className="t-bannerHome_logo">
          <div className="t-bannerHome_logo_item">
            <Image src={hospenLogo} ratio="342x144" />
          </div>
          <div className="t-bannerHome_logo_item">
            <Image src={abbottLogo} ratio="167x76" />
          </div>
        </div>
        <div className="t-bannerHome_title">
          <Text modifiers={['white', 'center', '26x39', 'brandon']}>
            HỘI THẢO KHOA HỌC
          </Text>
        </div>
        <div className="t-bannerHome_sologan">
          <Text modifiers={['linearGoldNew', 'center', '32x40', '700', 'brandonGrotesque', 'linearShadow']}>
            NÂNG CAO CHĂM SÓC DINH DƯỠNG
          </Text>
        </div>
        <div className="t-bannerHome_sologan">
          <Text modifiers={['linearGoldNew', 'center', '32x40', '700', 'brandonGrotesque', 'linearShadow']}>
            CHO BỆNH NHÂN NGOẠI KHOA
          </Text>
        </div>
        <div className="t-bannerHome_sologan section">
          <Text modifiers={['linearGoldNew', 'center', '32x40', '700', 'brandonGrotesque', 'linearShadow']}>
            NHU CẦU THIẾT THỰC GIÚP HỒI PHỤC SỚM
          </Text>
        </div>
        <div className="t-bannerHome_sologan">
          <Text modifiers={['linearGoldNew', 'center', '32x40', '700', 'brandonGrotesque', 'linearShadow']}>
            SAU PHẪU THUẬT VÀ SAU XUẤT VIỆN
          </Text>
        </div>
        <div className="t-bannerHome_image">
          <Image src={bannerDecor} srcTablet={isHorizontal ? bannerDecor : bannerDecorMb} srcMobile={bannerDecorMb} ratio={isHorizontal ? '1068x1144' : '800x870'} />
        </div>
        <div className="t-bannerHome_datetime">
          <Text modifiers={['white', 'center', '20x28', '600', 'brandon']}>
            <Text type="span" modifiers={['dimGray']}>Thời gian: </Text>
            8:00 - 12:00 | Chủ nhật, 18.08.2024
          </Text>
          <Text modifiers={['white', 'center', '20x28', '600', 'brandon']}>
            <Text type="span" modifiers={['dimGray']}>Tại website: </Text>
            {' '}
            <a style={{ color: '#fff' }} href="https://www.hoithaoensure.com/" target="_blank" rel="noreferrer">www.hoithaoensure.com</a>
          </Text>
        </div>
        <div className="t-bannerHome_countdown">
          <div className="t-bannerHome_countdown_title">
            <Text modifiers={['white', 'center', '24x36', '400']}>
              Sự kiện diễn ra sau
            </Text>
          </div>
          <Countdown endTime={eventStart} />
        </div>
        <div className="t-bannerHome_button">
          <Button size="h56" onClick={handleClick}>
            ĐĂNG KÝ NGAY
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BannerHome;
