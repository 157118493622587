import { useCallback, useState } from 'react';

const useCountOpenOnceTime = () => {
  const [countPolicyOpen, setCountPolicyOpen] = useState(0);
  const handleListenerOpen = useCallback(() => {
    setCountPolicyOpen(countPolicyOpen + 1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    countPolicyOpen,
    handleListenerOpen,
  };
};
export default useCountOpenOnceTime;
