import { ActivityTrackingParams, TrackingOnlineParamsTypes, TrackingOnlineResponseTypes } from './type';

import axiosInstance from 'services/common/instance';
import { formatParams } from 'utils/functions';

const trackingOnlineService = async (
  data: TrackingOnlineParamsTypes,
): Promise<TrackingOnlineResponseTypes> => {
  const response = await axiosInstance.post('members/tracking-online', data);
  return response.data.data;
};

export const activityTrackingService = async (
  _params: ActivityTrackingParams,
) => {
  const params = formatParams(_params);

  await axiosInstance.post('members/activity-tracking', params);
};

export default trackingOnlineService;
