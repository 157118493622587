import {
  CheckRegisterType, InvitationCodeType, InvitationResponseType, RegisterMemberType,
} from './type';

import axiosInstance from 'services/common/instance';

const getBanner = async () => {
  const res = await axiosInstance.post('/example');
  return res.data;
};
export default getBanner;

export const registerMemberService = async (params: RegisterMemberType): Promise<any> => {
  const res = await axiosInstance.post('/members/register', params);
  return res.data;
};

export const checkRegisterService = async (params:CheckRegisterType): Promise<any> => {
  const res = await axiosInstance.post('/members/check-register', params);
  return res.data;
};

export const checkInvitationCodeService = async (
  params:InvitationCodeType,
): Promise<InvitationResponseType> => {
  const res = await axiosInstance.post('/members/check-invitation', params);
  return res.data;
};
