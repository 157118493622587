import React, { useRef } from 'react';

import doctorData, { agendaData, agendaData2, doctorData2 } from 'assets/data';
import Container from 'components/organisms/Container';
import Footer from 'components/organisms/Footer';
import Agenda from 'components/templates/Agenda';
import Banner from 'components/templates/Banner';
import BannerHome from 'components/templates/Banner/component/home';
import Register from 'components/templates/Register';
import RegisterInstruction from 'components/templates/RegisterInstruction';
import TestimonialsTemplate from 'components/templates/Testimonials';
import { scrollDownNextSection } from 'utils/functions';

const HomeContainer = () => {
  const registerRef = useRef<HTMLDivElement>(null);
  return (
    <>
      {/* Banner  */}
      <Banner customContent>
        <BannerHome
          eventStart="2024-08-18T08:00:00+07:00"
          handleClick={() => scrollDownNextSection(registerRef)}
        />
      </Banner>
      <section className="p-home_testimonials">
        <Container>
          <TestimonialsTemplate
            dataTestimonials={doctorData}
            dataTestimonials2={doctorData2}
          />
        </Container>
      </section>
      <section className="p-home_agenda">
        <Agenda
          agendaData={agendaData}
          agendaData2={agendaData2}
        />
      </section>
      <section className="p-home_register" ref={registerRef}>
        <Register />
      </section>
      <section className="p-home_instruction">
        <Container>
          <RegisterInstruction />
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default HomeContainer;
