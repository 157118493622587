/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';

interface RatingStarProps {
  selected: boolean;
  handleClick: () => void;
}
interface RatingProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  isShowText?: boolean;
  starsSelected: number;
  handleClick?: (val: number) => void;
}
const StarRating: React.FC<RatingStarProps> = ({ selected, handleClick }) => (
  <div className={selected ? 'star selected' : 'star'} onClick={handleClick} />
);

const Rating = forwardRef<HTMLInputElement, RatingProps>(({
  name, starsSelected, isShowText, handleClick, ...innerProps
}, ref,) => (
  <div className="m-rating">
    {[...Array(5)].map((n, i) => (
      <div className="m-rating_star" key={`item${i.toString()}`}>
        <StarRating
          selected={i < starsSelected}
          handleClick={() => {
            if (handleClick) { handleClick(i); }
          }}
        />
        {isShowText && (
          <>
            {
              i === 0 && (
                <div className="m-rating_label m-rating_label-left">
                  Cực kỳ không hài lòng
                </div>
              )
            }
            {
              i === 4 && (
                <div className="m-rating_label m-rating_label-right">
                  Cực kỳ hài lòng
                </div>
              )
            }
          </>
        )}

      </div>
    ))}
    <input type="hidden" ref={ref} value={starsSelected} name={name} {...innerProps} />
  </div>
));

export default Rating;
