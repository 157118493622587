import { SurveyAnswerParams, SurveyQuestionDataTypes } from './type';

import axiosInstance from 'services/common/instance';

const getSurveyQuestionsService = async (): Promise<SurveyQuestionDataTypes[]> => {
  const res = await axiosInstance.get('survey-questions');
  return res.data.data;
};

export const postSurveyAnswerService = async (params: SurveyAnswerParams): Promise<void> => { await axiosInstance.post('members/update-survey-exam', params); };

export default getSurveyQuestionsService;
