import React from 'react';
import { useNavigate } from 'react-router-dom';

import BackgroundPanel from '../BackgroundPanel';

import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Text from 'components/atoms/Text';
import eventConfig from 'configs';
import { useAppSelector } from 'store/hooks';

interface WaitingLivestreamProps {
}

const WaitingLivestreamPopup: React.FC<WaitingLivestreamProps> = () => {
  const { data: systemData } = useAppSelector((state) => state.system);
  const [showNotice, setShowNotice] = React.useState(false);
  const navigate = useNavigate();
  return (
    <div className="t-waitingLivestream">
      <BackgroundPanel
        classname="t-waitingLivestream"
        modifier="secondary"
      >
        <div className="block">
          <Text modifiers={['pattrickBlue', '24x32', 'center', '400']}>
            CẢM ƠN QUÝ CHUYÊN VIÊN Y TẾ ĐÃ HOÀN THÀNH
          </Text>
          <Text modifiers={['pattrickBlue', '24x32', 'center', '400']}>
            TRẢI NGHIỆM TÌM HIỂU THÔNG TIN
          </Text>
        </div>
        <div className="block">
          <Heading type="h3" modifiers={['32x40', 'pattrickBlue', '700', 'center', 'uppercase']}>
            CHƯƠNG TRÌNH HỘI THẢO KHOA HỌC TRỰC TUYẾN
          </Heading>
        </div>
        <div className="block">
          <Heading type="h2" modifiers={['40x56', 'linearGoldNew1', '700', 'center', 'uppercase']}>
            NÂNG CAO CHĂM SÓC DINH DƯỠNG
            <br />
            CHO BỆNH NHÂN NGOẠI KHOA
            <br />
            NHU CẦU THIẾT THỰC GIÚP HỒI PHỤC SỚM
            <br />
            SAU PHẪU THUẬT VÀ SAU XUẤT VIỆN
          </Heading>
        </div>
        <div className="block">
          <Text modifiers={['pattrickBlue', '16x24', 'center']}>
            SẼ BẮT ĐẦU VÀO LÚC 9:00
          </Text>
        </div>
        <div className="block">
          <Text modifiers={['pattrickBlue', '16x24', 'center']}>
            XIN QUÝ CHUYÊN VIÊN Y TẾ VUI LÒNG CHỜ TRONG GIÂY LÁT
          </Text>
        </div>
        <div className="t-waitingLivestream_button">
          <Button onClick={() => {
            if (systemData?.redirectToPage === 'LIVESTREAM') navigate(eventConfig.SLUG_PARAMS.LIVESTREAM);
            else setShowNotice(true);
          }}
          >
            Vào hội thảo
          </Button>
        </div>
        {showNotice && (
          <p className="t-waitingLivestream_error">
            Chương trình hội thảo chưa bắt đầu.
            <br />
            Xin mời Quý Chuyên viên Y Tế vui lòng chờ trong giây lát.
          </p>
        )}
      </BackgroundPanel>
    </div>
  );
};

WaitingLivestreamPopup.defaultProps = {
};

export default WaitingLivestreamPopup;
